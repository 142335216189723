<template>
  <div class="graph-card">
    <div class="cards">
      <mini-card
        v-for="(card, index) in miniCards"
        :key="`mini-card-${index}`"
        class="card"
        :class="selectedTab === index ? 'selected-tab' : ''"
        :total="card.total"
        :label="card.label"
        :icon="card.icon"
        :info="card.info"
        :is-time-value="card.isTimeValue || false"
        :loading="loading"
        :format-label="formatTabLabel"
        @click.native="changeTab(index)"
      >
        <comparation
          :total-comparison="card.comparison"
          :loading="loading"
        />
      </mini-card>
    </div>

    <div class="main-card">
      <loading-card v-if="loading" />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import MiniCard from '@/components/Dashboard/cards/MiniCard.vue'
import Comparation from '@/components/Dashboard/base/Comparation.vue'
import LoadingCard from '@/components/Dashboard/loading/LoadingCard.vue'

export default {
  name: 'GraphCard',
  components: {
    MiniCard,
    Comparation,
    LoadingCard
  },
  props: {
    miniCards: Array,
    formatTabLabel: Boolean,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedTab: 0
    }
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
      this.$emit('change-tab', index)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.graph-card {
  .cards {
    display: flex;
    @include mobile {
      overflow-x: scroll;
    }
    .card {
      position: relative;
      z-index: 1;
      padding: 16px;
      width: 326px;
      border-radius: 6px 6px 0px 0px;
      margin-right: 10px;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      transition: all ease 0.3s;

      &.selected-tab {
        background: #FDFDFD;
      }

      &:not(.selected-tab) {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .main-card {
    position: relative;
    z-index: 3;
    background: #FDFDFD;
    padding: 16px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 6px 6px;
  }

}
</style>
