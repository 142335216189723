<template>
  <div>
    <svg
      v-if="kaledo && isGrey"
      :width="kaledoWidth"
      :height="kaledoHeight"
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 475 100"
      style="enable-background:new 0 0 1920 415.6;"
      xml:space="preserve"
    >
      <path
        d="M25.4829 0.0125732C11.4118 0.0125732 0.0126953 11.1998 0.0126953 25.0094V75.0031C0.0126953 88.8127 11.4118 99.9999 25.4829 99.9999C39.5541 99.9999 50.9532 88.8127 50.9532 75.0031V25.0094C50.9532 11.1998 39.5541 0.0125732 25.4829 0.0125732ZM36.024 75.0031C36.024 80.729 31.2928 85.3483 25.4829 85.3483C19.6486 85.3483 14.9418 80.705 14.9418 75.0031V25.0094C14.9418 19.2835 19.6731 14.6642 25.4829 14.6642C31.3173 14.6642 36.024 19.3075 36.024 25.0094V75.0031Z"
        fill="#667085"
      />
      <path
        d="M94.4164 7.32583C84.4636 -2.44194 68.3333 -2.44194 58.405 7.32583L7.46457 57.3195C-2.48819 67.0873 -2.48819 82.9178 7.46457 92.6615C17.4173 102.405 33.5477 102.429 43.4759 92.6615L94.4164 42.6679C104.369 32.9241 104.369 17.0936 94.4164 7.32583ZM32.9348 82.3164C28.8164 86.3582 22.124 86.3582 18.0057 82.3164C13.8873 78.2745 13.8873 71.7065 18.0057 67.6647L68.9461 17.6951C73.0645 13.6532 79.7569 13.6532 83.8753 17.6951C87.9936 21.7369 87.9936 28.3049 83.8753 32.3467L32.9348 82.3164Z"
        fill="#667085"
      />
      <path
        d="M76.4234 50.0063C62.3522 50.0063 50.9531 61.1936 50.9531 75.0032C50.9531 88.8128 62.3522 100 76.4234 100C90.4945 100 101.894 88.8128 101.894 75.0032C101.894 61.1936 90.4945 50.0063 76.4234 50.0063ZM76.4234 85.3484C70.589 85.3484 65.8823 80.7051 65.8823 75.0032C65.8823 69.2773 70.6135 64.658 76.4234 64.658C82.2577 64.658 86.9645 69.3013 86.9645 75.0032C86.9645 80.7291 82.2577 85.3484 76.4234 85.3484Z"
        fill="#667085"
      />
      <path
        d="M183.486 33.7364H163.801L146.764 55.2207V12.3484H130.462V89.3358H146.764V67.1057L164.904 89.3358H184.148L160.688 61.2113L183.486 33.7364Z"
        fill="#667085"
      />
      <path
        d="M225.062 38.2835C220.821 34.2657 215.257 32.2448 208.442 32.2448C200.891 32.2448 194.346 35.1077 188.953 40.7615C183.584 46.3912 180.863 53.3922 180.863 61.5481C180.863 69.7039 183.584 76.705 188.953 82.3347C194.346 87.9884 200.891 90.8514 208.442 90.8514C215.257 90.8514 220.821 88.8305 225.062 84.8127V89.3598H241.34V33.7364H225.062V38.2835ZM221.091 51.4675C223.763 54.0899 225.062 57.3859 225.062 61.524C225.062 65.6862 223.763 68.9581 221.091 71.5805C218.419 74.2029 215.134 75.478 211.04 75.478C206.946 75.478 203.686 74.2029 201.063 71.5805C198.415 68.9581 197.141 65.6621 197.141 61.5C197.141 57.3378 198.415 54.0418 201.063 51.4194C203.711 48.797 206.971 47.5219 211.04 47.5219C215.134 47.5701 218.419 48.8452 221.091 51.4675Z"
        fill="#667085"
      />
      <path
        d="M268.134 9.14856H251.856V89.3357H268.134V9.14856Z"
        fill="#667085"
      />
      <path
        d="M305.787 32.2207C297.084 32.2207 289.877 35.0356 284.313 40.6171C278.748 46.1747 275.929 53.2238 275.929 61.524C275.929 69.8964 278.797 76.9696 284.411 82.5031C290.049 88.0366 297.599 90.8274 306.866 90.8274C317.652 90.8274 325.962 86.8096 331.527 78.9184L332.213 77.932L318.976 70.4498L318.387 71.2196C315.838 74.5157 312.136 76.1276 307.086 76.1276C299.855 76.1276 295.319 73.5533 293.285 68.2845H333.733L333.905 67.3703C334.272 65.3253 334.469 63.3525 334.469 61.524C334.469 53.296 331.748 46.295 326.379 40.6893C320.961 35.0837 314.048 32.2207 305.787 32.2207ZM297.109 49.4226C299.364 47.6904 302.281 46.8243 305.787 46.8243C308.729 46.8243 311.376 47.6422 313.681 49.2782C315.642 50.6736 317.039 52.7186 317.848 55.4132H292.991C293.8 52.9111 295.172 50.8901 297.109 49.4226Z"
        fill="#667085"
      />
      <path
        d="M385.066 38.2594C380.899 34.2657 375.359 32.2448 368.544 32.2448C360.92 32.2448 354.325 35.1078 348.957 40.7615C343.588 46.3912 340.867 53.3923 340.867 61.5481C340.867 69.704 343.588 76.705 348.957 82.3347C354.35 87.9885 360.944 90.8514 368.544 90.8514C375.334 90.8514 380.874 88.8305 385.066 84.8368V89.3598H401.344V12.3724H385.066V38.2594ZM371.167 75.5021C367.073 75.5021 363.788 74.227 361.116 71.6046C358.444 68.9822 357.145 65.6862 357.145 61.5481C357.145 57.386 358.444 54.114 361.116 51.4916C363.788 48.8693 367.073 47.5942 371.167 47.5942C375.236 47.5942 378.521 48.8693 381.144 51.4916C383.792 54.114 385.066 57.4101 385.066 61.5722C385.066 65.7343 383.792 69.0303 381.144 71.6527C378.496 74.227 375.236 75.5021 371.167 75.5021Z"
        fill="#667085"
      />
      <path
        d="M460.251 40.7134C454.465 35.0837 447.307 32.2207 438.997 32.2207C430.662 32.2207 423.529 35.0837 417.792 40.7134C412.056 46.3431 409.139 53.3441 409.139 61.524C409.139 69.7039 412.056 76.705 417.792 82.3347C423.529 87.9644 430.662 90.8274 438.997 90.8274C447.332 90.8274 454.465 87.9644 460.251 82.3347C466.036 76.705 468.953 69.7039 468.953 61.524C468.953 53.3682 466.012 46.3671 460.251 40.7134ZM452.676 61.5481C452.676 65.5659 451.401 68.7657 448.754 71.3399C446.106 73.9383 442.919 75.1893 438.997 75.1893C435.05 75.1893 431.888 73.9383 429.289 71.3399C426.691 68.7416 425.416 65.5418 425.416 61.524C425.416 57.5063 426.691 54.3065 429.289 51.7081C431.888 49.1339 435.05 47.8588 438.997 47.8588C442.944 47.8588 446.131 49.1098 448.754 51.7081C451.377 54.3305 452.676 57.5303 452.676 61.5481Z"
        fill="#667085"
      />
    </svg>
    <svg
      v-else-if="kaledo && !isGrey"
      :width="kaledoWidth"
      :height="kaledoHeight"
      viewBox="0 0 149 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.4982 11.4735H52.295L46.9262 18.3921V4.58582H41.7891V29.3783H46.9262V22.2195L52.6427 29.3783H58.7068L51.314 20.3213L58.4982 11.4735Z"
        fill="#121E48"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.3625 10.9929C68.51 10.9929 70.2636 11.6437 71.6 12.9376V11.4733H76.7294V29.3859H71.6V27.9216C70.2636 29.2154 68.51 29.8662 66.3625 29.8662C63.9832 29.8662 61.9206 28.9443 60.2211 27.1236C58.5293 25.3106 57.6719 23.056 57.6719 20.4296C57.6719 17.8031 58.5293 15.5485 60.2211 13.7356C61.9206 11.9149 63.9832 10.9929 66.3625 10.9929ZM71.6001 20.4214C71.6001 19.0888 71.1907 18.0274 70.3487 17.1829C69.5066 16.3384 68.4715 15.9278 67.1814 15.9123C65.8991 15.9123 64.8717 16.3229 64.0374 17.1674C63.2031 18.0119 62.8014 19.0733 62.8014 20.4137C62.8014 21.754 63.2031 22.8155 64.0374 23.6599C64.8639 24.5044 65.8914 24.9151 67.1814 24.9151C68.4715 24.9151 69.5066 24.5044 70.3487 23.6599C71.1907 22.8155 71.6001 21.7618 71.6001 20.4214Z"
        fill="#121E48"
      />
      <rect
        x="80.043"
        y="3.55542"
        width="5.12939"
        height="25.823"
        fill="#121E48"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.2713 13.6894C92.0249 11.892 94.296 10.9855 97.0384 10.9855C99.6417 10.9855 101.82 11.9074 103.527 13.7127C105.219 15.5179 106.077 17.7724 106.077 20.4221C106.077 21.0109 106.015 21.6463 105.899 22.3048L105.845 22.5992H93.0987C93.7399 24.296 95.169 25.125 97.4478 25.125C99.0392 25.125 100.206 24.6059 101.009 23.5444L101.194 23.2965L105.366 25.706L105.15 26.0237C103.396 28.5649 100.777 29.8588 97.3783 29.8588C94.4583 29.8588 92.079 28.9601 90.3022 27.1781C88.5332 25.3961 87.6294 23.1183 87.6294 20.4221C87.6294 17.7492 88.5178 15.4791 90.2713 13.6894ZM97.0384 15.6886C95.9337 15.6886 95.0144 15.9675 94.3037 16.5253C93.6935 16.9979 93.2609 17.6488 93.006 18.4545H100.839C100.584 17.5868 100.144 16.9282 99.5258 16.4789C98.7997 15.952 97.9654 15.6886 97.0384 15.6886Z"
        fill="#121E48"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M116.814 10.9936C118.961 10.9936 120.707 11.6444 122.02 12.9305V4.59399H127.15V29.3865H122.02V27.93C120.699 29.2161 118.954 29.8669 116.814 29.8669C114.419 29.8669 112.341 28.9449 110.642 27.1242C108.95 25.3112 108.092 23.0567 108.092 20.4302C108.092 17.8038 108.95 15.5492 110.642 13.7362C112.333 11.9155 114.411 10.9936 116.814 10.9936ZM114.473 23.6691C115.315 24.5136 116.351 24.9242 117.641 24.9242C118.923 24.9242 119.95 24.5136 120.785 23.6846C121.619 22.8401 122.021 21.7787 122.021 20.4383C122.021 19.098 121.619 18.0365 120.785 17.192C119.958 16.3475 118.923 15.9369 117.641 15.9369C116.351 15.9369 115.315 16.3475 114.473 17.192C113.631 18.0365 113.222 19.0902 113.222 20.4306C113.222 21.7632 113.631 22.8246 114.473 23.6691Z"
        fill="#121E48"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M139.016 10.9855C141.635 10.9855 143.89 11.9074 145.714 13.7204C147.529 15.5411 148.456 17.7957 148.456 20.4221C148.456 23.0563 147.537 25.3109 145.714 27.1239C143.89 28.9368 141.642 29.8588 139.016 29.8588C136.389 29.8588 134.141 28.9368 132.334 27.1239C130.526 25.3109 129.607 23.0563 129.607 20.4221C129.607 17.7879 130.526 15.5334 132.334 13.7204C134.141 11.9074 136.389 10.9855 139.016 10.9855ZM142.09 23.5831C142.925 22.7541 143.326 21.7236 143.326 20.4298C143.326 19.1359 142.917 18.1055 142.09 17.261C141.264 16.4242 140.26 16.0214 139.016 16.0214C137.772 16.0214 136.776 16.432 135.957 17.261C135.138 18.0977 134.736 19.1282 134.736 20.422C134.736 21.7159 135.138 22.7463 135.957 23.5831C136.776 24.4198 137.772 24.8227 139.016 24.8227C140.252 24.8227 141.256 24.4198 142.09 23.5831Z"
        fill="#121E48"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.54736 0.00390625C4.12827 0.00390625 0.54834 3.58384 0.54834 8.00293V24.001C0.54834 28.4201 4.12827 32 8.54736 32C12.9665 32 16.5464 28.4201 16.5464 24.001V8.00293C16.5464 3.58384 12.9665 0.00390625 8.54736 0.00390625ZM11.8581 24.0008C11.8581 25.8331 10.3722 27.3113 8.5476 27.3113C6.7153 27.3113 5.23713 25.8254 5.23713 24.0008V8.00279C5.23713 6.17048 6.72299 4.69232 8.5476 4.69232C10.3799 4.69232 11.8581 6.17818 11.8581 8.00279V24.0008Z"
        fill="#FF6362"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8872 2.34428C22.0052 -0.781425 27.071 -0.781425 30.1967 2.34428C33.3224 5.46998 33.3224 10.5358 30.1967 13.6538L14.1987 29.6518C11.0807 32.7775 6.0149 32.7698 2.8892 29.6518C-0.236503 26.5338 -0.236503 21.468 2.8892 18.3423L18.8872 2.34428ZM6.19921 26.3411C7.4926 27.6345 9.59437 27.6345 10.8878 26.3411L26.8858 10.3508C28.1792 9.05737 28.1792 6.95561 26.8858 5.66221C25.5924 4.36882 23.4906 4.36882 22.1973 5.66221L6.19921 21.6526C4.90582 22.946 4.90582 25.0477 6.19921 26.3411Z"
        fill="#00B2E3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5454 16.0011C20.1263 16.0011 16.5464 19.581 16.5464 24.0001C16.5464 28.4192 20.1263 31.9991 24.5454 31.9991C28.9645 31.9991 32.5444 28.4192 32.5444 24.0001C32.5444 19.581 28.9645 16.0011 24.5454 16.0011ZM24.5455 27.3103C22.7132 27.3103 21.235 25.8244 21.235 23.9998C21.235 22.1675 22.7209 20.6893 24.5455 20.6893C26.3778 20.6893 27.856 22.1752 27.856 23.9998C27.856 25.8321 26.3778 27.3103 24.5455 27.3103Z"
        fill="#FFD200"
      />
    </svg>

    <svg
      v-else-if="kaledoK && !isWhite"
      :width="width"
      :heigth="height"
      viewBox="0 0 141 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1372 0.0168457C15.7348 0.0168457 0.0168457 15.6809 0.0168457 35.0168V105.017C0.0168457 124.353 15.7348 140.017 35.1372 140.017C54.5396 140.017 70.2576 124.353 70.2576 105.017V35.0168C70.2576 15.6809 54.5396 0.0168457 35.1372 0.0168457ZM49.6721 105.017C49.6721 113.034 43.1483 119.502 35.1372 119.502C27.0923 119.502 20.6023 113 20.6023 105.017V35.0168C20.6023 26.9995 27.1261 20.5318 35.1372 20.5318C43.1821 20.5318 49.6721 27.0332 49.6721 35.0168V105.017Z"
        fill="#FF6362"
      />
      <path
        d="M130.189 10.2575C116.465 -3.41915 94.2233 -3.41915 80.5335 10.2575L10.2927 80.2575C-3.43091 93.9341 -3.43091 116.1 10.2927 129.743C24.0164 143.385 46.2582 143.419 59.948 129.743L130.189 59.7425C143.912 46.0996 143.912 23.9341 130.189 10.2575ZM45.4131 115.257C39.7344 120.917 30.5064 120.917 24.8276 115.257C19.1489 109.598 19.1489 100.402 24.8276 94.7425L95.0684 24.7762C100.747 19.1169 109.975 19.1169 115.654 24.7762C121.333 30.4355 121.333 39.6319 115.654 45.2911L45.4131 115.257Z"
        fill="#00B2E3"
      />
      <path
        d="M105.378 70.0168C85.9755 70.0168 70.2576 85.6809 70.2576 105.017C70.2576 124.353 85.9755 140.017 105.378 140.017C124.78 140.017 140.498 124.353 140.498 105.017C140.498 85.6809 124.78 70.0168 105.378 70.0168ZM105.378 119.502C97.333 119.502 90.843 113 90.843 105.017C90.843 96.9995 97.3668 90.5318 105.378 90.5318C113.423 90.5318 119.913 97.0332 119.913 105.017C119.913 113.034 113.423 119.502 105.378 119.502Z"
        fill="#FFD200"
      />
    </svg>

    <svg
      v-else-if="kaledoK && isWhite"
      :width="width"
      :heigth="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#FDFDFD"
        fill-rule="evenodd"
        d="M8.00244.00390625C3.58335.00390625.00341797 3.58384.00341797 8.00293V24.001C.00341797 28.4201 3.58335 32 8.00244 32c4.41906 0 7.99906-3.5799 7.99906-7.999V8.00293c0-4.41909-3.58-7.99902375-7.99906-7.99902375ZM11.3132 24.0008c0 1.8323-1.48591 3.3105-3.31052 3.3105-1.83231 0-3.31047-1.4859-3.31047-3.3105V8.00279c0-1.83231 1.48586-3.31047 3.31047-3.31047 1.83231 0 3.31052 1.48586 3.31052 3.31047V24.0008Z"
        clip-rule="evenodd"
      />
      <path
        fill="#FDFDFD"
        fill-rule="evenodd"
        d="M18.3423 2.34428c3.118-3.125705 8.1838-3.125705 11.3095 0 3.1257 3.1257 3.1257 8.19152 0 11.30952l-15.998 15.998c-3.118 3.1257-8.18382 3.118-11.30952 0-3.125705-3.118-3.125705-8.1838 0-11.3095L18.3423 2.34428ZM5.65429 26.3411c1.29339 1.2934 3.39515 1.2934 4.68851 0l15.9981-15.9903c1.2934-1.29343 1.2934-3.39519 0-4.68859-1.2934-1.29339-3.3952-1.29339-4.6886 0L5.65429 21.6526c-1.2934 1.2934-1.2934 3.3951 0 4.6885Z"
        clip-rule="evenodd"
      />
      <path
        fill="#FDFDFD"
        fill-rule="evenodd"
        d="M24.0005 16.0011c-4.4191 0-7.999 3.5799-7.999 7.999s3.5799 7.999 7.999 7.999 7.999-3.5799 7.999-7.999-3.5799-7.999-7.999-7.999Zm.0001 11.3092c-1.8323 0-3.3105-1.4859-3.3105-3.3105 0-1.8323 1.4859-3.3105 3.3105-3.3105 1.8323 0 3.3105 1.4859 3.3105 3.3105 0 1.8323-1.4782 3.3105-3.3105 3.3105Z"
        clip-rule="evenodd"
      />
    </svg>

    <svg
      v-else
      viewBox="0 0 363.64 101.46"
      width="108"
      :class="{
        '-white': isWhite,
        '-grey': isGrey
      }"
      class="c-logo"
    >
      <defs>
        <linearGradient
          id="logoGradient"
          x1="202.43"
          y1="419.49"
          x2="137.63"
          y2="342.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stop-color="#8347FF"
          />
          <stop
            offset="1"
            stop-color="#8246FF"
          />
        </linearGradient>
      </defs>
      <path d="M195.46,380.43c0-9.84,6.58-17.87,17.2-17.87s17.2,8,17.2,17.87-6.58,17.87-17.2,17.87S195.46,390.27,195.46,380.43Zm-4.91,0c0,13.34,8.76,22.78,22.11,22.78s22.12-9.45,22.12-22.78-8.77-22.78-22.12-22.78S190.55,367.1,190.55,380.43Z" />
      <path d="M212.66,405.12c-14.14,0-24-10.15-24-24.69s9.88-24.69,24-24.69,24,10.15,24,24.69S226.8,405.12,212.66,405.12Zm0-45.57c-12.09,0-20.21,8.39-20.21,20.88s8.12,20.88,20.21,20.88,20.22-8.39,20.22-20.88S224.75,359.55,212.66,359.55Zm0,40.65c-11.07,0-19.1-8.32-19.1-19.77s8-19.77,19.1-19.77,19.11,8.32,19.11,19.77S223.73,400.21,212.66,400.21Zm0-35.74c-9,0-15.3,6.57-15.3,16s6.29,16,15.3,16,15.3-6.57,15.3-16S221.67,364.47,212.66,364.47Z" />
      <path d="M244.47,401.91h3.8a0.55,0.55,0,0,0,.55-0.55V380.27c0-11.9,6.89-17.71,15.53-17.71,9.22,0,11.26,6.84,11.26,13.86v24.93a0.55,0.55,0,0,0,.55.55H280a0.55,0.55,0,0,0,.55-0.55V376.15c0-9.47-2.63-18.5-16.18-18.5-7.28,0-13,3.82-15.34,8.57h-0.27c-0.16-.53-0.24-3.71-0.32-6.73a0.55,0.55,0,0,0-.55-0.54H244a0.55,0.55,0,0,0-.56.56c0.06,2.64.46,8.6,0.46,11.53v30.31A0.55,0.55,0,0,0,244.47,401.91Z" />
      <path d="M281.88,403.81h-7.62a0.55,0.55,0,0,1-.55-0.55V376.43c0-8.27-2.88-12-9.35-12-8.53,0-13.63,5.91-13.63,15.8v23a0.55,0.55,0,0,1-.55.55h-7.61a0.55,0.55,0,0,1-.55-0.55V371c0-1.67-.14-4.45-0.26-6.9-0.11-2.21-.2-4.12-0.2-5.19V357.6a0.55,0.55,0,0,1,.55-0.55h7.63a0.55,0.55,0,0,1,.55.54l0,1.86v0.5a0.55,0.55,0,0,0,.91.41,20.21,20.21,0,0,1,13.1-4.61c18.08,0,18.08,15.35,18.08,20.4v27.11A0.55,0.55,0,0,1,281.88,403.81Zm-3.81-3.8h0a0.55,0.55,0,0,0,.55-0.55V376.15c0-11.63-4.27-16.6-14.27-16.6-6,0-11.39,2.95-13.63,7.5l-0.17.35c3-4.29,7.82-6.75,13.81-6.75s13.16,2.74,13.16,15.76v23A0.55,0.55,0,0,0,278.07,400Zm-31.7,0h0a0.55,0.55,0,0,0,.55-0.55V380.27a23.07,23.07,0,0,1,2.66-11.33,0.55,0.55,0,0,0-.49-0.82h-1.37a0.55,0.55,0,0,1-.53-0.4l-0.28-1a31,31,0,0,1-.34-5.36,0.56,0.56,0,0,0-.55-0.54h0a0.55,0.55,0,0,0-.55.58c0,0.78.08,1.64,0.12,2.52,0.12,2.5.26,5.33,0.26,7.09v28.41A0.55,0.55,0,0,0,246.37,400Z" />
      <path d="M304,401.91h4.71a0.77,0.77,0,0,0,.72-0.49L325.76,360a0.77,0.77,0,0,0-.72-1.05h-4a0.77,0.77,0,0,0-.72.5l-13,34.64a0.77,0.77,0,0,1-1.44,0l-13.38-34.65a0.77,0.77,0,0,0-.72-0.49h-4.64a0.77,0.77,0,0,0-.71,1.06l16.92,41.42A0.77,0.77,0,0,0,304,401.91Z" />
      <path d="M310.05,403.81h-7.28a0.77,0.77,0,0,1-.71-0.48l-18.47-45.23a0.77,0.77,0,0,1,.71-1.06h8.78a0.77,0.77,0,0,1,.72.49l12,31.21a0.77,0.77,0,0,0,1.44,0L319,357.54a0.77,0.77,0,0,1,.72-0.5h8.11a0.77,0.77,0,0,1,.72,1.05l-17.78,45.23A0.77,0.77,0,0,1,310.05,403.81Zm-2.94-3.8h0.35a0.77,0.77,0,0,0,.72-0.49L323,361.9a0.77,0.77,0,0,0-.72-1.05h0a0.61,0.61,0,0,0-.57.4Zm-1.89,0h0a0.61,0.61,0,0,0,.57-0.83l-14.6-37.83a0.77,0.77,0,0,0-.72-0.49H290a0.77,0.77,0,0,0-.71,1.06l15.4,37.72A0.61,0.61,0,0,0,305.22,400Z" />
      <path d="M336.32,376.7a1.9,1.9,0,0,1-1.82-2.47c1.32-4.22,5.5-11.67,15.18-11.67a14.72,14.72,0,0,1,14.73,11.89,1.91,1.91,0,0,1-1.87,2.25H336.32ZM366.38,392a1.9,1.9,0,0,0-2.8.12,17.55,17.55,0,0,1-13.25,6.14c-11.35,0-15.31-9-16.15-14.48a1.91,1.91,0,0,1,1.88-2.21h31.55a1.9,1.9,0,0,0,1.9-1.9V377.8c0-10.05-6.31-20.15-19.56-20.15-12.59,0-20.87,9.63-20.87,22.52,0,13.63,8,23.05,21.15,23.05,7.1,0,13.32-2.27,17.27-7.87a1.9,1.9,0,0,0-.24-2.44Z" />
      <path d="M350.24,405.12c-13.79,0-23.05-10-23.05-25,0-14.38,9.36-24.42,22.77-24.42,14.74,0,21.46,11.43,21.46,22.06v3.82a1.9,1.9,0,0,1-1.9,1.9H338.45a1.9,1.9,0,0,0-1.82,2.45c1.4,4.63,5.14,10.42,13.7,10.42,5.17,0,10.43-2.84,12.79-6.91h0a1.9,1.9,0,0,1,3-.4l3.61,3.54A1.9,1.9,0,0,1,370,395h0C366.11,401.6,359.27,405.12,350.24,405.12ZM350,359.55c-11.17,0-19,8.48-19,20.62,0,12.85,7.55,21.15,19.25,21.15,6.88,0,12-2.22,15.36-6.6a0.65,0.65,0,0,0-.07-0.85h0a0.65,0.65,0,0,0-.94,0,19.44,19.44,0,0,1-14.26,6.3c-13.28,0-18.23-11.4-18.23-18.59h0a1.9,1.9,0,0,1,1.9-1.9h31.71a1.9,1.9,0,0,0,1.9-1.9h0C367.61,368.72,362.15,359.55,350,359.55Zm14.64,19.06H334a1.9,1.9,0,0,1-1.9-1.9h0c0-3.4,4.4-16,17.58-16,9.28,0,16.82,7.2,16.82,16h0A1.9,1.9,0,0,1,364.6,378.61Zm-25.42-3.8H360a1.91,1.91,0,0,0,1.75-2.66,13.06,13.06,0,0,0-12.08-7.68A13.33,13.33,0,0,0,337.49,372,1.9,1.9,0,0,0,339.18,374.8Z" />
      <path d="M380.71,401.91h3.34a0.79,0.79,0,0,0,.79-0.79V380.27c0-11.9,6.89-17.71,15.53-17.71,9.22,0,11.26,6.84,11.26,13.86v24.7a0.79,0.79,0,0,0,.79.79h3.35a0.79,0.79,0,0,0,.79-0.79v-25c0-9.47-2.63-18.5-16.18-18.5-7.28,0-13,3.82-15.34,8.57h-0.27c-0.15-.52-0.23-3.55-0.31-6.5a0.79,0.79,0,0,0-.79-0.77h-3.4a0.78,0.78,0,0,0-.79.8c0.08,2.75.45,8.45,0.45,11.29v30.08A0.79,0.79,0,0,0,380.71,401.91Z" />
      <path d="M417.66,403.81h-7.15a0.79,0.79,0,0,1-.79-0.79v-26.6c0-8.27-2.88-12-9.35-12-8.53,0-13.63,5.91-13.63,15.8V403a0.79,0.79,0,0,1-.79.79h-7.14A0.79,0.79,0,0,1,378,403V371c0-1.67-.14-4.45-0.26-6.9-0.11-2.21-.2-4.12-0.2-5.19v-1.12a0.79,0.79,0,0,1,.79-0.79h7.17a0.79,0.79,0,0,1,.79.77l0,1.63v0a0.79,0.79,0,0,0,1.27.6,20.31,20.31,0,0,1,12.76-4.32c18.08,0,18.08,15.35,18.08,20.4V403A0.79,0.79,0,0,1,417.66,403.81Zm-3.58-3.8h0a0.55,0.55,0,0,0,.55-0.55V376.15c0-11.63-4.27-16.6-14.27-16.6-6,0-11.4,2.94-13.63,7.5l-0.17.35c3-4.29,7.82-6.74,13.8-6.74s13.16,2.74,13.16,15.76v23A0.55,0.55,0,0,0,414.08,400Zm-31.7,0h0a0.55,0.55,0,0,0,.55-0.55V380.27a23.31,23.31,0,0,1,2.48-11,0.78,0.78,0,0,0-.69-1.16h-0.8a0.79,0.79,0,0,1-.76-0.57l-0.23-.8a31,31,0,0,1-.34-5.36,0.56,0.56,0,0,0-.55-0.54h0a0.55,0.55,0,0,0-.55.58c0,0.78.08,1.64,0.12,2.52,0.12,2.5.26,5.33,0.26,7.09v28.41A0.55,0.55,0,0,0,382.38,400Z" />
      <rect
        x="310.05"
        y="29.25"
        width="4.91"
        height="42.96"
        rx="0.55"
        ry="0.55"
      />
      <path d="M435.75,403.81h-7.61a0.55,0.55,0,0,1-.55-0.55V357.6a0.55,0.55,0,0,1,.55-0.55h7.61a0.55,0.55,0,0,1,.55.55v45.66A0.55,0.55,0,0,1,435.75,403.81Zm-3.8-3.8h0a0.55,0.55,0,0,0,.55-0.55v-38a0.55,0.55,0,0,0-.55-0.55h0a0.55,0.55,0,0,0-.55.55v38A0.55,0.55,0,0,0,431.95,400Z" />
      <path d="M427.07,341.75a4.24,4.24,0,1,0,4.24-4.24A4.22,4.22,0,0,0,427.07,341.75Z" />
      <path d="M431.31,347.9a6.14,6.14,0,1,1,6.15-6.14A6.15,6.15,0,0,1,431.31,347.9Zm0-8.48a2.34,2.34,0,1,0,2.34,2.34A2.31,2.31,0,0,0,431.31,339.41Z" />
      <path d="M474.68,380.24a0.56,0.56,0,0,1,.56.56v2.33c0,7.38-3.16,15.17-15.45,15.17-4.79,0-10.08-2.76-10.08-8.17,0-7.51,9.6-9.89,19.2-9.89h5.76Zm5.48-7.18c0-10.86-6.64-15.4-17.39-15.4-5.58,0-12.24,2-16,5.59a0.56,0.56,0,0,0,0,.74l2.22,2.95a0.56,0.56,0,0,0,.8.1,20.09,20.09,0,0,1,13-4.46c7.1,0,12.47,3,12.47,10.39v2a0.56,0.56,0,0,1-.56.56h-6.15c-8.73,0-23.72,1.36-23.72,14.86,0,9.26,7.22,12.8,14.8,12.8s12.28-2.55,15.54-8.05h0.19a42.72,42.72,0,0,0,.47,6.27,0.56,0.56,0,0,0,.56.47h4a0.56,0.56,0,0,0,.56-0.63,138.22,138.22,0,0,1-.76-17.49V373.05Z" />
      <path d="M459.61,405.12c-8.07,0-16.7-3.86-16.7-14.71,0-7.65,4.45-16.76,25.63-16.76h4.81V373c0-5.63-3.56-8.49-10.57-8.49a18.26,18.26,0,0,0-12.23,4.38l-1.08.91a0.56,0.56,0,0,1-.81-0.09l-4.4-5.84a0.56,0.56,0,0,1,0-.73l0.78-.82c4.29-4.51,12-6.53,17.7-6.53,15.94,0,19.29,9.41,19.29,17.31v10.74a130.78,130.78,0,0,0,.82,17.88l0.2,1.51a0.56,0.56,0,0,1-.56.64h-7.77a0.56,0.56,0,0,1-.56-0.47L474,402.23q-0.09-.51-0.16-1a0.56,0.56,0,0,0-.92-0.35C469.58,403.74,465.23,405.12,459.61,405.12Zm8.92-27.66c-14.68,0-21.82,4.24-21.82,13,0,9.84,9,10.9,12.9,10.9,5.84,0,9.74-1.58,12.59-5.19-2.94,2.66-7.14,4.08-12.4,4.08-5.81,0-12-3.53-12-10.08,0-7.5,7.69-11.8,21.1-11.8h7.67a0.56,0.56,0,0,1,.56.56v4.23a19.5,19.5,0,0,1-2.08,9.31,0.56,0.56,0,0,0,.5.82h1.11a0.56,0.56,0,0,1,.56.56v1.34a41.74,41.74,0,0,0,.24,4.34,0.56,0.56,0,0,0,.56.5h0.19a0.56,0.56,0,0,0,.56-0.62c-0.39-4-.54-8.42-0.54-15.6V373.05c0-9.08-5.07-13.5-15.49-13.5a24,24,0,0,0-13.31,3.93,0.56,0.56,0,0,0-.11.79h0a0.56,0.56,0,0,0,.76.12,21.93,21.93,0,0,1,12.65-3.74c9.14,0,14.38,4.48,14.38,12.3v3.94a0.56,0.56,0,0,1-.56.56h-8.05Zm0.39,4.68c-5.2,0-17.29.78-17.29,8,0,4.07,4.21,6.27,8.17,6.27,9,0,13.55-4.47,13.55-13.27V382.7a0.56,0.56,0,0,0-.56-0.56h-3.86Z" />
      <path
        :class="{'-art': !isWhite}"
        d="M173.35,336.37a41.68,41.68,0,0,1,25.54,10.75,3.3,3.3,0,0,0,4.54-.11,3.34,3.34,0,0,0-.11-4.84,47.91,47.91,0,0,0-29.56-12.45A3.34,3.34,0,0,0,173.35,336.37Zm25.34,77.56a41.67,41.67,0,0,1-25.34,10.58,3.33,3.33,0,0,0,.4,6.65,47.9,47.9,0,0,0,29.34-12.25,3.35,3.35,0,0,0,.14-4.84A3.3,3.3,0,0,0,198.69,413.93Zm-62-4.76a44.33,44.33,0,0,1-.06-57.41,3.36,3.36,0,0,0-.16-4.57,3.31,3.31,0,0,0-4.83.18,51,51,0,0,0,.06,66.2,3.3,3.3,0,0,0,4.83.17A3.36,3.36,0,0,0,136.65,409.17Zm57-4.73a3.08,3.08,0,0,0-4.18-.18,30.54,30.54,0,0,1-41.24-2.12,31,31,0,0,1,.19-43.59,30.51,30.51,0,0,1,41.24-1.78,3.09,3.09,0,0,0,4.06-4.67,36.66,36.66,0,0,0-49.64,2,37.17,37.17,0,0,0-.22,52.35A36.65,36.65,0,0,0,193.49,409,3.09,3.09,0,0,0,193.66,404.44Z"
      />
      <path d="M181.75,392.68a0.34,0.34,0,0,0-.49,0,16.14,16.14,0,0,1-18,3.51,17.19,17.19,0,0,1,.05-31.55,16.16,16.16,0,0,1,18,3.59,0.34,0.34,0,0,0,.48,0l3.48-3.49a0.37,0.37,0,0,0,0-.52,21.51,21.51,0,0,0-24.07-4.85,22.22,22.22,0,0,0-13.82,19.4A23.07,23.07,0,0,0,162.49,402a21.48,21.48,0,0,0,22.73-5.28,0.37,0.37,0,0,0,0-.52Z" />
      <path d="M169.85,405.17a24.05,24.05,0,0,1-8-1.39,24.86,24.86,0,0,1-16.36-25.14,24.05,24.05,0,0,1,15-21,23.49,23.49,0,0,1,26.15,5.27,2.24,2.24,0,0,1,.66,1.57,2.29,2.29,0,0,1-.66,1.63l-3.48,3.49a2.19,2.19,0,0,1-1.56.66,2.21,2.21,0,0,1-1.62-.66A14.21,14.21,0,0,0,164,366.4a14.91,14.91,0,0,0-9.22,14,14.74,14.74,0,0,0,9.18,14,14.18,14.18,0,0,0,15.92-3.11,2.25,2.25,0,0,1,3.17,0h0l3.48,3.51a2.27,2.27,0,0,1,0,3.2A23.5,23.5,0,0,1,169.85,405.17Zm0-45.68a20.78,20.78,0,0,0-20.57,19.41,21,21,0,0,0,13.82,21.29A19.47,19.47,0,0,0,182,397.08a0.93,0.93,0,0,0,.09-1.39h0a0.94,0.94,0,0,0-1.24-.07A18,18,0,0,1,162.53,398a19.09,19.09,0,0,1,.06-35.06,18.08,18.08,0,0,1,18.28,2.41,0.94,0.94,0,0,0,1.24-.06h0a0.93,0.93,0,0,0-.09-1.39A19.35,19.35,0,0,0,169.87,359.49Z" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isWhite: Boolean,
    isGrey: Boolean,
    kaledo: Boolean,
    kaledoK: Boolean,
    kaledoWidth: {
      type: [String, Number],
      default: '149px'
    },
    kaledoHeight: {
      type: [String, Number],
      default: '32px'
    },
    width: {
      type: [String, Number],
      default: 32
    },
    height: {
      type: [String, Number],
      default: 32
    }
  }
}
</script>

<style lang="scss">
$c-logo-fill-color: #283048 !default;

.c-logo {
  & {
      margin: auto;
      fill: $c-logo-fill-color;
      fill-rule: evenodd;
  }

  &.-white > path,
  &.-white > rect {
      fill: #fff;
  }

  &.-grey > path,
  &.-grey > rect {
      fill: #858CA3;
  }

  & > .-art {
      fill: url(#logoGradient);
  }

  & > path { transform: translate(-119.44px, -329.7px); }
}
</style>
