<template>
  <div class="campaign-tab-card">
    <div
      v-if="title"
      class="header"
    >
      <h3 class="title">
        <k-icon
          v-if="iconTitle"
          :icon="iconTitle"
          :is-colorful="true"
          :number-of-colors="6"
          class="icon-title"
        />
        {{ title }}
      </h3>
      <info-button
        :icon="icon"
        :info="info || null"
        :is-colorful="colorfulIcon"
        :number-of-colors="3"
      />
    </div>

    <div class="content">
      <div
        class="half-content"
        v-for="(value, index) in data"
        :key="index"
      >
        <loading-bar v-if="loading" />
        <div
          v-else
          class="total"
        >
          <span
            class="total-value"
            :data-cy="`campaigns-bar-card-total-${index}`"
          >
            {{ formatTotal(value.total) }}
          </span>
          <info-button
            v-if="!title"
            class="icon"
            :icon="icon"
            :info="value.info"
          />
        </div>
        <h4 class="label">
          {{ formatLabel(value.label)[0] }}
          <br />
          no período
        </h4>
        <comparation
          v-if="value.comparisonValue !== 'not-set'"
          :total-comparison="value.comparisonValue"
          :loading="loading"
          remove-text-on-mobile
        />
      </div>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'
import Comparation from '@/components/Dashboard/base/Comparation.vue'

export default {
  name: 'CampaignBarCard',
  components: {
    LoadingBar,
    Comparation,
    InfoButton,
    KIcon
  },
  props: {
    title: String,
    total: String,
    label: String,
    icon: String,
    iconTitle: String,
    data: Array,
    loading: Boolean,
    isMoney: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    colorfulIcon () {
      if (this.icon === 'kaledo-vida' || this.icon === 'kaledo-money') return true
      return false
    }
  },
  methods: {
    formatLabel (label) {
      if (label === undefined) return ''
      if (label.includes('no período')) {
        return label.split('no período')
      }
      return label
    },
    formatTotal (total) {
      if (!total) {
        return '-'
      } else if (this.isMoney) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(total / 100)
      }
      return parseFloat(total).toLocaleString('pt-BR')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
@import '~@/assets/kaledo-font/icons.css';
.campaign-tab-card {
  max-width: 500px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  transition: all ease 0.3s;

  > .selected-tab {
    background: #FDFDFD;
  }

  &:not(.selected-tab) {
    &:hover {
      opacity: 0.5;
    }
  }

  .icon {
    color: #5E6684;
  }

  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: $base-color;
      font-family: $base-font-family;
      font-weight: 700;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
      }
      .icon-title {
        margin-right: 8px  ;
      }
    }
    @include mobile {
      margin-bottom: 15px;
    }
  }
  .content {
    display: flex;

    .half-content {
      padding-right: 10px;
      width: 100%;

      .total {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .total-value {
          font-family: $base-font-family;
          line-height: 20px;
          color: #202950;
          font-weight: 700;
          font-size: 18px;
          @include mobile {
            font-size: 16px;
          }
        }
      }

      .label {
        font-family: $base-font-family;
        width: 100%;
        line-height: 20px;
        color: #5E6684;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 16px;
        height: 40px;
        @include mobile {
          height: auto;
          font-size: 14px;
          line-height: 15px
        }
      }

      .comparison-loading-bar {
        margin-top: 5px;
        width: 50px;
        height: 16px;
      }

      .comparison-content {
        font-family: $base-font-family;
        font-size: 14px;
        color: #5E6684;
      }
      .comparison-positive::before {
        content: '↑';
        color: #4CAD50;
        margin-right: 5px;
      }
      .comparison-negative::before {
        content: '↓';
        color: #EE5250;
        margin-right: 5px;
      }
    }
  }
}
</style>
