<template>
  <div
    class="empty"
    :data-cy="`not-available-${type}`"
    :style="{height: `${height}px`}"
  >
    <div class="content">
      <k-icon
        class="icon"
        :icon="icon"
      />
      <p class="text">
        {{ text }}
      </p>
      <p
        v-if="subtext"
        class="subtext"
      >
        {{ subtext }}
      </p>
      <slot />
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  name: 'NotAvailable',
  components: {
    KIcon
  },
  props: {
    type: {
      type: String,
      default: 'empty'
    },
    height: {
      type: Number,
      default: 353
    }
  },
  computed: {
    icon () {
      if (this.type === 'empty') return 'calendar-schedule-cross-delete'
      else if (this.type === 'construction') return 'calendar-schedule-clock'
      else if (this.type === 'error') return 'warning-retry-alert'
      return 'lock-shine-square'
    },
    text () {
      if (this.type === 'empty') return 'Sem dados disponíveis para o período selecionado'
      else if (this.type === 'construction') return 'Área em construção'
      else if (this.type === 'error') return 'Tivemos um problema ao carregar as informações.'
      return 'Funcionalidade não ativa no seu clube'
    },
    subtext () {
      return this.type === 'error' ? 'Por favor tente novamente mais tarde' : ''
    }
  }
}
</script>

<style lang='scss' scoped>
.empty {
  width: 100%;
  height: 353px;
  padding: 10px;
  background: #F7F8FC;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    color: #BEC0D3;
    text-align: center;
    font-size: 16px;
    .icon {
      font-size: 84px;
      padding-bottom: 4px;
    }
    .subtext {
      margin-top: 20px;
      width: 100%;
      color: rgba(255, 89, 103, 1);
      text-transform: uppercase;
    }
  }
}
</style>
