<template>
  <section class="highlight-container">
    <c-spinner
      v-if="isLoading"
      size="md"
    />

    <component
      v-if="!isLoading"
      :is="isDesktop ? 'c-side-toggle' : 'div'"
      :side-open="sidetoggle"
      :display-controls="false"
      @side-toggle="sidetoggle = !sidetoggle"
    >
      <highlight-featured
        :is-hidden="!isDesktop && showListModal"
        :drag-options="dragOptions"
        :featured="featuredList"
        :list-dragging="isDragging.list"
        :loading="loading"

        @save-highlighted="handleSave($event)"
        @is-dragging="isDragging.featured = $event"
        @open-list="showListModal = true"

        @add-item="addToFeatured"
        @remove-item="removeFromFeatured"
        @sort="sortFeatured"
        @close="sidetoggle = !sidetoggle"
      />
    </component>

    <highlight-list
      v-if="!isLoading"
      :campaigns="activeCampaigns"
      :pagination-loading="loadingPagination"
      :filters="filters"
      :search-term="filters.name"
      :drag-options="dragOptions"
      :opened-list="sidetoggle && isDesktop"
      :is-opened="showListModal"
      :show-pagination="showListPagination"
      :selected-campaigns="selectedItems"
      :show-warning="featuredList"
      :has-campaigns="!!(campaigns.length || highlighted.length)"
      :featured-dragging="!!isDragging.featured"

      @paginate-list="paginateList"
      @update-filters="updateFilters"
      @search-campaigns="searchCampaigns"
      @clear-filters="clearFilters"
      @apply-filters="load"
      @open-filters="isFiltersOpened = true"
      @close-list="closeAction"
      @close="showListModal = false"
      @save-highlighted="savedHighlighted"

      @is-dragging="isDragging.list = $event"
      @add-item="addToCampaigns"
      @remove-item="(ev) => isDesktop ? null : addToFeatured(ev)"
    />

    <filter-menu
      v-if="!isDesktop"
      :is-opened="isFiltersOpened"
      :filters="filters"
      @clear-filters="clearFilters"
      :show-status="showStatus = false"
      @apply-filters="load"
      @update-filters="updateFilters"
      @close="isFiltersOpened = false"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import {
  CONFIG_COMPANY_ID,
  CAMPAIGN_LIST_PAGINATION,
  CAMPAIGN_LIST_HIGHLIGHTED,
  CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW,
  CAMPAIGN_SET_HIGHLIGHTED,
  CAMPAIGN_LIST_FILTER
} from '@/store/types'

import HighlightFeatured from '@/components/Communication/Highlight/HighlightFeatured'
import HighlightList from '@/components/Communication/Highlight/HighlightList'
import CSideToggle from '@/components/CComponents/CSideToggle'
import FilterMenu from '@/components/Filter/FilterMenu'

export default {

  components: {
    HighlightFeatured,
    HighlightList,
    CSideToggle,
    FilterMenu
  },
  mixins: [Loadable],

  data () {
    return {
      sidetoggle: true,
      isDragging: { list: false, featured: false },
      isFiltersOpened: false,
      loadingPagination: false,
      loading: false,
      showListModal: false,
      selectedItems: [],
      filters: {
        name: '',
        asc: null,
        desc: null,
        categories: [],
        exclusives: this.companyId || '',
        highlighted: '0'
      },
      first: false
    }
  },

  computed: {
    ...mapGetters({
      companyId: CONFIG_COMPANY_ID,
      campaigns: CAMPAIGN_LIST_PAGINATION,
      highlighted: CAMPAIGN_LIST_HIGHLIGHTED,
      highlightedSaved: CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW
    }),

    dragOptions () {
      return {
        sort: false,
        animation: 0,
        group: 'campaigns',
        ghostClass: 'ghost',
        disabled: (this.featuredList.length >= 10 && !this.isDragging.featured) || !this.isDesktop,
        forceFallback: true,
        fallbackOnBody: true,
        fallbackTolerance: 2
      }
    },

    featuredList () {
      if (!this.first) {
        return this.selectedItems.reduce((selected, slug) => [
          ...selected,
          ...[...this.campaigns.list, ...this.highlighted]
            .filter(_ => _.slug === slug)
        ], [])
      } else {
        this.first = true
        return this.highlightedSaved
      }
    },

    activeCampaigns () {
      const sortList = (a, b) => {
        if (this.isDesktop || (!this.filters.asc && !this.filters.desc)) return 0

        return this.filters.asc
          ? a.partner.name.toLowerCase() > b.partner.name.toLowerCase()
          : a.partner.name.toLowerCase() < b.partner.name.toLowerCase()
      }

      const campaignsFiltered = this.campaigns.list.filter(campaign => {
        let notSelectedItems = true

        if (this.isDesktop) notSelectedItems = !this.selectedItems.includes(campaign.slug)
        return notSelectedItems
      })

      const highlightFiltered = this.highlighted.filter(highlighted => {
        if (this.isDesktop) return !this.selectedItems.includes(highlighted.slug)

        this.highlighted.filter(highligted => {
          if (!this.filters.categories.length || this.isDesktop) return true

          return this.filters.categories.includes((highligted.partner || {}).categories[0].id)
        })
      })

      return [
        ...campaignsFiltered,
        ...highlightFiltered
      ].sort(sortList)
    },

    showListPagination () {
      if (this.campaigns && this.campaigns.list && this.campaigns.total) {
        return this.campaigns.list.length < this.campaigns.total
      }
      return false
    }
  },

  watch: {
    highlighted (newVal) {
      this.selectedItems = newVal.map((c) => c.slug)
    }
  },

  methods: {
    ...mapActions({
      paginateCampaigns: CAMPAIGN_LIST_PAGINATION,
      savedHighlighted: CAMPAIGN_SET_HIGHLIGHTED,
      getCampaigns: CAMPAIGN_LIST_FILTER,
      updateHighlightedSaved: CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW
    }),

    campaignsFiltered () {
      return this.campaigns.filter(campaign => campaign.status !== 0)
    },

    updateFilters (property) {
      this.filters = { ...this.filters, ...property, status: 1 }
    },

    async closeAction () {
      await this.load()
      this.showListModal = false
    },

    clearFilters () {
      this.filters.asc = null
      this.filters.desc = null

      this.filters.categories = []
    },

    async handleSave (campaigns) {
      this.loading = true
      await this.saveAndClose(campaigns)
      this.loading = false
    },

    saveAndClose (campaigns) {
      this.savedHighlighted(campaigns)
      this.updateHighlightedSaved('')
      this.sidetoggle = false
    },

    addToCampaigns (ev) {
      if (ev.oldIndex !== undefined && !this.featuredList[ev.oldIndex]) return

      const slug = ev.slug ? ev.slug : this.featuredList[ev.oldIndex].slug

      this.selectedItems = this.selectedItems.filter((cSlug) => {
        return cSlug !== slug
      })

      this.updateHighlightedSaved(this.featuredList)
    },

    addToFeatured (ev) {
      if (ev.oldIndex !== undefined && !this.activeCampaigns[ev.oldIndex]) return
      if (this.featuredList.length >= 10) return

      const slug = ev.slug ? ev.slug : this.activeCampaigns[ev.oldIndex].slug

      if (this.selectedItems.includes(slug)) return

      this.selectedItems.splice(ev.newIndex, 0, slug)

      this.updateHighlightedSaved(this.featuredList)
    },

    removeFromFeatured (ev) {
      // Quando for desktop não é necessário chamar a função 'addToCampaigns', pois ela
      // já é executada no evento 'add-item' do componente HighlightList
      if (this.isDesktop) null
      else this.addToCampaigns(ev)
    },

    sortFeatured (ev) {
      if (ev.newIndex >= this.featuredList.length) {
        return false
      }

      const oldPosition = this.selectedItems[ev.oldIndex]
      const newPosition = this.selectedItems[ev.newIndex]

      this.selectedItems.splice(ev.newIndex, 1, oldPosition)
      this.selectedItems.splice(ev.oldIndex, 1, newPosition)
      this.isDragging.featured = false
    },

    async searchCampaigns () {
      if (this.filters.name.length > 0) {
        await this.getCampaigns({ ...this.filters, status: '1' })
      }
    },

    async paginateList () {
      const page = this.campaigns.current_page + 1

      this.loadingPagination = true
      this.paginateCampaigns({ ...this.filters, page, status: '1' })
      this.loadingPagination = false
    },

    async load () {
      await Promise.all([
        this.getCampaigns({
          ...this.filters,
          exclusives: this.companyId,
          status: '1'
        }),
        this.getCampaigns({
          highlighted: '1',
          ...this.highlightedSaved,
          status: '1',
          limit: 10
        })
      ])

      let filtered = this.highlighted

      if ((this.highlighted || []).length > 10) {
        filtered = this.highlighted.slice(this.highlighted.length - 10)
      }

      const slugs = filtered.map((campaign, i) => (
        { slug: campaign.slug, order: i })
      )

      this.filters = { ...this.filters, exclusives: this.companyId }
    }

  }
}
</script>
