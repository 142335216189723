<template>
  <div
    class="comparation"
    v-if="$can('compare-with-last-period')"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loading-bar
        v-if="loading"
      />
      <div
        v-else
        class="arrow"
      >
        <k-icon
          :icon="icon"
          :class="`icon ${classes}`"
        />
        <span class="number">{{ total }}</span>
        <span
          v-if="totalComparison === null"
          class="text"
        >Não há parâmetros para comparação com período anterior</span>
      </div>
    </transition>
    <span
      v-if="totalComparison !== null || loading"
      class="text"
      :class="removeTextOnMobile ? 'remove-text-mobile' : ''"
    >comparação com período anterior</span>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'

export default {
  name: 'Comparation',
  components: {
    KIcon,
    LoadingBar
  },
  props: {
    totalComparison: [String, Number],
    loading: {
      type: Boolean,
      default: false
    },
    removeTextOnMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatedTotal () {
      return parseFloat(this.totalComparison)
    },
    icon () {
      if (this.formatedTotal === 0) return 'copy-item1'
      else if (!this.formatedTotal) return 'infornation-info'
      else if (this.formatedTotal > 0) return 'arrow-up1'
      return 'arrow-down'
    },
    classes () {
      if (this.formatedTotal === 0) return ''
      if (this.formatedTotal === null) return '-none'
      if (this.formatedTotal > 0) return '-success'
      return '-error'
    },
    total () {
      if (this.formatedTotal == null) return ''
      return `${this.formatedTotal}%`
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.comparation {
  margin-top: 12px;
  color: #5E6684;
  font-size: 14px;
  .arrow {
    display: flex;
    align-items: center;
    .icon {
      color: #5E6684;
      padding-right: 4px;
      &.-none {
        padding-top: 3px;
        align-self: flex-start;
      }
      &.-success {
        color: #4CAD50;
      }
      &.-error {
        color: #FF6362;
      }
    }
  }
  .text {
    font-size: 14px;
    @include tablet {
      font-size: 12px;
    }
    @include mobile {
      font-size: 12px;
    }
    &.remove-text-mobile {
      @include mobile {
        display: none;
      }
    }
  }
}
</style>
