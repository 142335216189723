<template>
  <div class="first-cards">
    <products-bar-card
      data-cy="awardsData"
      class="products-bar-tab-card"
      :loading="isAwardsGraphLoading"
      :error="hasAwardsGraphError"
      :tab-titles="awardsTabTitles"
      :tabs="awardsGraphTabs"
      :columns="awardsColumns"
      :reload-data-query="reloadDataQuery"
      :is-second-tab-table="true"
      :labels="awardsTableLabels"
      :results="awardsTableResults"
      :error-table="hasAwardsTablesError"
      :names="awardsNames"
      is-money
    />
  </div>
</template>

<script>
import { PRODUCTS_DASH_AWARDS_BAR_DATA, PRODUCTS_DASH_AWARDS_TABLE_DATA } from '@/store/types'
import { mapActions } from 'vuex'
import ProductsBarCard from '@/components/Dashboard/cards/ProductsBarCard.vue'

export default {
  name: 'AwardsData',
  components: {
    ProductsBarCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      isAwardsGraphLoading: true,
      hasAwardsTablesError: false,
      hasAwardsGraphError: false,
      awardsNames: [
        {
          data1: 'Distribuído',
          data2: 'Resgatado'
        }
      ],
      awardsGraphTabs: [
        [
          {
            chart_title: 'Distribuídos no período',
            label: 'Distribuídos no período',
            icon: 'info-information-circle',
            total: 0
          },
          {
            chart_title: 'Resgatados no período',
            label: 'Resgatados no período',
            icon: 'info-information-circle',
            total: 0
          }
        ],
        [
          {
            total: 0,
            label: 'Distribuídos no período',
            icon: 'info-information-circle',
            chart_title: 'Resgatados em ofertas no período'
          },
          {
            total: 0,
            label: 'Resgatados no período',
            icon: 'info-information-circle',
            chart_title: 'Resgatados em saques no período'
          }
        ]
      ],
      awardsColumns: [
        [
          ['x', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
          ['Distribuído', 0, 0, 0, 0, 0, 0],
          ['Resgatado', 0, 0, 0, 0, 0, 0]
        ],
        [
          ['x', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
          ['Distribuído', 0, 0, 0, 0, 0, 0],
          ['Resgatado', 0, 0, 0, 0, 0, 0]
        ]
      ],
      awardsTableResults: []
    }
  },
  computed: {
    awardsTabTitles () {
      return [
        {
          chart_title: 'Prêmios',
          title: 'Prêmios distribuídos',
          icon: 'kaledo-premios-trofeu',
          info: {
            title: 'Prêmios distribuídos',
            text: 'Valor distribuído em forma de prêmio.'
          }
        },
        {
          chart_title: 'Ofertas de prêmio',
          title: 'Ofertas de prêmio',
          icon: 'kaledo-premios-trofeu',
          info: {
            title: 'Ofertas de prêmio',
            text: 'São as ofertas que os membros do seu clube utilizaram para resgatar o seu saldo de prêmios.'
          }
        }
      ]
    },
    awardsTableLabels () {
      return [
        {
          name: 'Parceiro',
          column: 'partner_name'
        },
        {
          name: 'Nome da Oferta',
          column: 'campaign_name'
        },
        {
          name: 'Resgates',
          column: 'total_redemptions'
        }
      ]
    }
  },
  watch: {
    reloadDataQuery (newval) {
      if (newval) {
        this.loadGraphs()
      }
    }
  },
  mounted () {
    this.loadGraphs()
  },
  methods: {
    ...mapActions({
      getAwardsData: PRODUCTS_DASH_AWARDS_BAR_DATA,
      getAwardsTable: PRODUCTS_DASH_AWARDS_TABLE_DATA
    }),
    async loadGraphs () {
      this.isAwardsGraphLoading = true
      const response = await this.getAwardsData()
      const responseTable = await this.getAwardsTable()
      if (response && response.success) {
        this.loadData(response.data)
      } else {
        this.hasAwardsGraphError = true
      }
      if (responseTable && responseTable.success) {
        this.loadData2(responseTable.data)
      } else {
        this.hasAwardsTablesError = true
      }
      this.isAwardsGraphLoading = false
    },
    loadData (data1) {
      this.awardsColumns = [data1.columns]
      this.awardsNames = { ...data1.names }
      this.awardsGraphTabs[0] = data1.tabs
    },
    loadData2 (data2) {
      this.awardsGraphTabs[1] = data2.tabs
      this.awardsTableResults = data2.data
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
