<template>
  <div class="partners-card">
    <div class="partners-tabs">
      <campaigns-bar-card
        v-for="(tab, index) in tabs"
        :key="index"
        class="card"
        :class="selectedTab === index ? 'selected-tab' : ''"
        :data="tab"
        :loading="loading"
        :title="tabTitles[index].title"
        :icon-title="tabTitles[index].icon"
        icon="info-information-circle"
        :data-cy="`product-bar-tab-${index}`"
        :is-money="isMoney"
        :info="tabTitles[index].info || null"
        @click.native="changeTab(index)"
      />
    </div>
    <div class="main-card">
      <loading-card
        v-if="loading"
      />
      <div v-else>
        <div v-if="isSecondTabTable && selectedTab === 1">
          <dash-long-table
            :error="errorTable"
            :labels="labels"
            :results="results"
          />
        </div>
        <not-available
          v-else-if="error || isDataEmpty"
          :type="error ? 'error' : 'empty'"
        />
        <vertical-bar
          v-else
          class="partners-chart-card"
          :title="selectedTitle"
          :categories="categories"
          :columns="selectedColumns"
          :names="selectedNames"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingCard from '@/components/Dashboard/loading/LoadingCard.vue'
import VerticalBar from '@/components/Dashboard/base/VerticalBar.vue'
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import notAvailable from '../base/notAvailable.vue'
import DashLongTable from '@/components/Dashboard/base/DashLongTable.vue'

export default {
  name: 'ProductsBarCard',
  components: {
    LoadingCard,
    notAvailable,
    VerticalBar,
    CampaignsBarCard,
    DashLongTable
  },
  props: {
    loading: Boolean,
    error: Boolean,
    tabTitles: Array,
    tabs: Array,
    names: [Array, Object],
    columns: Array,
    reloadDataQuery: Boolean,
    isMoney: {
      type: Boolean,
      default: false
    },
    isSecondTabTable: {
      type: Boolean,
      default: false
    },
    errorTable: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => []
    },
    results: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isLoading: false,
      selectedTab: 0,
      categories: ['x', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun']
    }
  },
  computed: {
    isDataEmpty () {
      if (typeof this.columns === 'undefined') {
        return true
      } else if (Array.isArray(this.columns)) {
        if (Array.isArray(this.columns[0])) {
          let isArrayEmpty = true
          this.columns.forEach(column => {
            column.forEach(col => {
              if (col[0] !== 'x') {
                const colum = col.slice(1)
                if (Math.max(...colum) !== 0) {
                  isArrayEmpty = false
                }
              }
            })
          })
          return isArrayEmpty
        } else {
          const isEmpty = this.columns.findIndex(value => parseInt(value) !== 0)
          return isEmpty === -1
        }
      } else {
        const values = Object.values(this.columns)
        const isEmpty = values.findIndex(value => parseInt(value) !== 0)
        return isEmpty === -1
      }
    },
    selectedColumns () {
      return this.columns[this.selectedTab]
    },
    selectedNames () {
      if (Array.isArray(this.names)) return this.names[this.selectedColumns]
      return this.names
    },
    selectedTitle () {
      if (this.tabTitles && this.tabTitles.length > 0) {
        return this.tabTitles[this.selectedTab].chart_title
      }
      return this.tabs[this.selectedTab].chart_title
    }
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.partners-card {
  margin-top: 20px;

  > .partners-tabs {
    display: flex;

    > .card {
      cursor: pointer;
      background: #F8F9FD;
      width: 100%;
      height: 130px;
      padding: 16px;
      box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
      border-radius: 6px 6px 0 0;
      transition: all ease 0.3s;

      & :deep h4 {
        max-width: 260px;
      }

      &:not(.selected-tab) {
        &:hover {
          opacity: 0.5;
        }
      }
    }

     > .card:last-child {
      margin-left: 10px;
    }

    > .selected-tab {
      background: #FDFDFD;
    }
  }

  .main-card {
    position: relative;
    z-index: 3;
    background: #FDFDFD;
    padding: 16px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    border-radius: 0px 0px 6px 6px;
  }
}
</style>
