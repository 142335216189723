<template>
  <div class="dashboard-card product-card">
    <div
      v-if="title !== '' "
      class="title-container"
    >
      <k-icon
        :icon="icon"
        size="32"
        :is-colorful="true"
        :number-of-colors="6"
        class="title-icon"
      />
      <h3 class="title">
        {{ title }}
      </h3>
    </div>
    <div
      v-for="(card, index) in cardData.data"
      :key="`${card.label}-${index}`"
      class="data-card"
    >
      <transition name="fade">
        <loading-bar v-if="loading" />
        <h4
          v-else
          class="value"
          :data-cy="`product-resume-value-${index}`"
        >
          {{ activeTab === 'dashboard-communication-tab' ? card.value :getFormatedValue(card.value) }}
        </h4>
      </transition>
      <span class="label">
        {{ card.label }}
        <info-button
          icon="info-information-circle"
          class="resume-info"
          :info="graphData.infos[index] || null"
        />
        <br />
      </span>
      <span>{{ card.period ? card.period : 'no período' }}</span>
    </div>
    <div class="graphic-card">
      <transition name="fade">
        <loading-gauge v-if="loading" />
        <error-gauge
          data-cy="error-gauge"
          v-else-if="!loading && error"
        />
        <gauge-chart
          v-else
          data-cy="graph-chart"
          :graph-data="graphData"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'
import GaugeChart from '@/components/Dashboard/base/GaugeChart.vue'
import LoadingGauge from '@/components/Dashboard/loading/LoadingGauge.vue'
import ErrorGauge from '@/components/Dashboard/error/ErrorGauge.vue'
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'

export default {
  name: 'ProductResumeCard',
  components: {
    KIcon,
    LoadingBar,
    GaugeChart,
    LoadingGauge,
    ErrorGauge,
    InfoButton
  },
  props: {
    title: String,
    icon: String,
    activeTab: String,
    cardData: {
      type: Object,
      default: () => {}
    },
    graphData: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFormatedValue (value) {
      if (this.error) return '-'
      return value || '-'
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
@import '~@/styles/dashboard';

.product-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px 40px 24px;
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title {
      font-size: 28px;
      color: $base-color;
    }
  }
  .data-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .value {
      font-size: 32px;
      color: $base-color;
    }
    span {
      color: $primary-neutral-2;
      &.label {
        font-size: 18px;
        font-weight: 700;
        color: $base-color;
        display: flex;
        .resume-info {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
