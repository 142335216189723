<template>
  <div class="loading-gauge">
    <div class="multigraph">
      <div class="content">
        <k-icon
          icon="warning2"
          class="error-icon"
          size="20"
        />
        <p class="text">
          Erro ao carregar os dados
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  name: 'ErrorGauge',
  components: {
    KIcon
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

$circle-width: 194px;
$circle-height: 97px;
.loading-gauge {
  position: relative;
  .multigraph {
    width: $circle-width;
    height: $circle-height;
    &:before {
      content: '';
      width: $circle-width;
      height: $circle-height;
      position: absolute;
      border: 30px solid #f8f8f8;
      border-radius: $circle-width $circle-width 0 0;
      border-bottom: none;
    }
  }
  .content {
    position: absolute;
    left: 20%;
    top: 38%;
    text-align: center;
    max-width: 118px;
    .error-icon {
      color: $failure-color;
    }
    .text {
      margin-bottom: 0px;
    }
  }
}
</style>
