// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/pattern-oferta.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active,.fade-leave-active{transition:opacity .3s}.fade-enter,.fade-enter-from,.fade-leave-to{opacity:0}.slide-fade-enter-active{transition:all .1s ease}.slide-fade-leave-active{transition:all .4s cubic-bezier(1,.5,.8,1)}.slide-fade-enter,.slide-fade-leave-to{transform:translateY(-50px)}.campaign-header{background-color:#fff!important;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x}.campaign-header>.content{align-items:center;display:flex;flex-direction:column}@media only screen and (min-width:769px){.campaign-header>.content{margin-left:auto;margin-right:auto;max-width:1020px;padding:0 10px;width:100%}}.campaign-header>.content>.header-actions{align-items:center;display:flex;flex-flow:row nowrap;height:66px;justify-content:flex-end;padding:10px 10px 16px;width:100%}.campaign-header>.content>.header-actions>:not(:first-child){margin-left:10px}.campaign-header>.content>.partner{padding:0 10px;text-align:center}.campaign-header>.content>.partner:first-child{margin-top:65px}.campaign-header>.content>.partner>.partner-logo{background-position:50%;background-repeat:no-repeat;background-size:contain;margin:20px 0;max-height:100px;max-width:160px}.campaign-header>.content>.partner>.name{color:#121e48;color:var(--color-primary)}.campaign-header>.content>.partner>.category{color:rgba(18,30,72,.5);font-size:14px;line-height:19px;margin-top:4px}.campaign-header>.content>.toggler{max-width:560px;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
