<template>
  <div class="step-one">
    <div class="step-form">
      <div class="duration-config">
        <h3 class="section-title">
          Duração
        </h3>
        <instruction
          class="instruction"
          text="Defina a duração da sua campanha estabelecendo uma data de início e fim."
        />
        <duration-inputs
          :start-date.sync="startDate"
          :end-date.sync="endDate"
          :disabled-start-dates="disabledStartDates"
          :disabled-end-dates="disabledEndDates"
          :invalid-initial-date-message="invalidStartDateMessage"
          :invalid-end-date-message="invalidEndDateMessage"
        />
      </div>
      <div class="type-config">
        <h3 class="section-title">
          Tipo da notificação
        </h3>
        <instruction
          class="instruction"
          text="Configure se a sua notificação será atrelada a alguma oferta do seu clube"
        />
        <notification-type-inputs
          :radio-option.sync="radioOption"
          :radio-buttons="radioButtons"
          :campaign.sync="campaign"
          :campaigns="campaigns"
          :partner.sync="partner"
          :partners="partners"
          :partners-loading="partnersLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Instruction from '@/components/General/Instruction.vue'
import DurationInputs from './StepOne/DurationInputs.vue'
import NotificationTypeInputs from './StepOne/NotificationTypeInputs.vue'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'

export default {
  components: {
    Instruction,
    DurationInputs,
    NotificationTypeInputs
  },
  props: {
    stepValue: Object,
    partnersLoading: Boolean,
    partners: Array
  },
  data () {
    return {
      ptBR,
      partner: null,
      campaign: null,
      title: '',
      description: '',
      value: '',
      showStartDate: false,
      showEndDate: false,
      radioGroupName: 'hasOffer',
      radioOption: 'false',
      radioButtons: [
        {
          label: 'Sim',
          value: 'yes'
        },
        {
          label: 'Não',
          value: 'no'
        }
      ]
    }
  },
  computed: {
    startDate: {
      get () {
        return (typeof (this.stepValue || {}).start_date === 'string' && !!(this.stepValue || {}).start_date)
          ? moment((this.stepValue || {}).start_date).format('DD/MM/YYYY')
          : (this.stepValue || {}).start_date
      },

      set (value) {
        this.showStartDate = false

        const date = typeof value === 'object'
          ? value.toLocaleDateString('pt-BR')
          : value

        this.update('start_date', date)
      }
    },
    endDate: {
      get () {
        return (typeof (this.stepValue || {}).end_date === 'string' && !!(this.stepValue || {}).end_date)
          ? moment((this.stepValue || {}).end_date).format('DD/MM/YYYY')
          : (this.stepValue || {}).end_date
      },

      set (value) {
        this.showEndDate = false

        const date = typeof value === 'object'
          ? value.toLocaleDateString('pt-BR')
          : value

        this.update('end_date', date)
      }
    },
    disabledStartDates () {
      return {
        to: new Date(moment().subtract(1, 'days'))
      }
    },
    disabledEndDates () {
      const hasStartDate = !!this.startDate
      return hasStartDate
        ? {
          to: new Date(moment(this.startDate, 'DD/MM/YYYY').add(1, 'days'))
        }
        : {
          to: new Date(moment().subtract(1, 'days'))
        }
    },
    campaigns () {
      if (!this.partners) return []
      const partner = this.partners.find(findedPartner => findedPartner.id === this.partner)
      return partner ? partner.campaigns.filter((campaign) => campaign.status === 1) : []
    },
    invalidStartDateMessage () {
      const formated_start_date = moment(this.startDate, 'DD/MM/YYYY', true)
      const formated_end_date = moment(this.endDate, 'DD/MM/YYYY', true)
      const isOrderValid = !this.startDate || !this.endDate || formated_start_date.isSameOrBefore(formated_end_date)
      const isEndDateFilled = this.endDate && !this.startDate

      if (this.startDate && !formated_start_date.isValid()) {
        return 'Data inválida'
      } else if (!isOrderValid) {
        return 'Data inicial deve ser anterior à data final'
      } else if (isEndDateFilled) {
        return 'Data inicial é obrigatória quando existe uma data final'
      } else {
        return ''
      }
    },
    invalidEndDateMessage () {
      const today = moment()
      const formated_start_date = moment(this.startDate, 'DD/MM/YYYY', true)
      const formated_end_date = moment(this.endDate, 'DD/MM/YYYY', true)
      const isOrderValid = !this.startDate || !this.endDate || formated_end_date.isSameOrAfter(formated_start_date)
      const isAfterToday = !this.endDate || formated_end_date.isAfter(today)

      if (!!this.endDate && !formated_end_date.isValid()) {
        return 'Data inválida'
      } else if (!isOrderValid) {
        return 'Data final deve ser posterior à data inicial'
      } else if (!isAfterToday) {
        return 'Data final deve ser posterior à data atual'
      } else {
        return ''
      }
    }
  },
  watch: {
    radioOption () {
      this.update('enable_campaign_config', this.radioOption)
    },
    partner () {
      this.update('partner', this.partner)
    },
    campaign () {
      this.update('campaign', this.campaign)
    }
  },
  created () {
    this.radioOption = this.stepValue.enable_campaign_config
    Object.keys(this.stepValue).forEach(key => {
      this[key] = this.stepValue[key]
    })
  },
  methods: {
    update (key, value) {
      key === 'end_date' || key === 'start_date'
        ? this.$emit('input', { ...this.stepValue, [key]: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') })
        : this.$emit('input', { ...this.stepValue, [key]: value })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.step-one {
  max-width: 1020px;
  height: 100%;
  padding: 0;

  > .step-one-title {
    display: flex;
    height: 35px;

    > .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  .section-title {
    margin-top: 48px;
    margin-bottom: 8px;
    color: #565F7D;
  }

  .instruction {
    margin-bottom: 24px;
  }

  & > .title {
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202950;
  }

  .input {
    max-width: 328px;
    gap: 8px;
    margin-bottom: 8px;

    .value-input {
      width: 100px;
    }

    :deep .label {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 24px;
      color: #5E6684;

      .required {
        display: none;
      }
    }
    & > .subtext {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #5E6684;
      padding-top: 6px;

      &:first-of-type {
        margin-bottom: 32px;
      }
    }
  }
  .duration-inputs {
    display: flex;

    .input-div {
      display: flex;
      align-items: flex-end;
      gap: 8px;

      &:first-of-type {
        margin-right: 24px;
      }
      .input {
        width: 300px;
      }
      .subtext {
        color: #5E6684;
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
