<template>
  <div class="first-cards">
    <products-bar-card
      data-cy="engagementData"
      class="products-bar-tab-card"
      :loading="isEngagementGraphLoading"
      :error="hasEngagementGraphError"
      :tab-titles="engagementTabTitles"
      :tabs="engagementGraphTabs"
      :columns="engagementColumns"
      :reload-data-query="reloadDataQuery"
      :is-second-tab-table="true"
      :labels="engagementTableLabels"
      :results="engagementTableResults"
      :error-table="hasEngagementTableError"
      :names="engagementNames"
      is-money
    />
  </div>
</template>

<script>
import { PRODUCTS_DASH_ENGAGEMENT_BAR_DATA, PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA } from '@/store/types'
import { mapActions } from 'vuex'
import ProductsBarCard from '@/components/Dashboard/cards/ProductsBarCard.vue'

export default {
  name: 'EngagementData',
  components: {
    ProductsBarCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      isEngagementGraphLoading: false,
      hasEngagementGraphError: false,
      hasEngagementTableError: false,
      engagementNames: {
        data1: 'Distribuído',
        data2: 'Resgatado'
      },
      engagementGraphTabs: [
        [
          {
            chart_title: 'Distribuídos no período',
            label: 'Distribuídos no período',
            icon: 'info-information-circle',
            total: 0
          },
          {
            chart_title: 'Resgatados no período',
            label: 'Resgatados no período',
            icon: 'info-information-circle',
            total: 0
          }
        ],
        [
          {
            chart_title: 'Resgatados em ofertas no período',
            label: 'Distribuídos no período',
            icon: 'info-information-circle',
            total: 0
          },
          {
            chart_title: 'Resgatados em saques no período',
            label: 'Resgatados no período',
            icon: 'info-information-circle',
            total: 0
          }
        ]
      ],
      engagementColumns: [
        [
          ['Distribuído', 0, 0, 0, 0, 0, 0],
          ['Resgatado', 0, 0, 0, 0, 0, 0]
        ],
        [
          ['Distribuído', 0, 0, 0, 0, 0, 0],
          ['Resgatado', 0, 0, 0, 0, 0, 0]
        ]
      ],
      engagementTableResults: []
    }
  },
  computed: {
    engagementTabTitles () {
      return [
        {
          chart_title: 'Pontos',
          title: 'Pontos distribuídos',
          icon: 'kaledo-premios',
          info: {
            title: 'Pontos distribuídos',
            text: 'Valor distribuído em forma de pontos.'
          }
        },
        {
          chart_title: 'Ofertas do tipo recompensa',
          title: 'Ofertas do tipo recompensa',
          icon: 'kaledo-premios',
          info: {
            title: 'Ofertas do tipo recompensa',
            text: 'São as ofertas que os membros do seu clube utilizaram para resgatar o seu saldo de pontos.'
          }
        }
      ]
    },
    engagementTableLabels () {
      return [
        {
          name: 'Parceiro',
          column: 'partner_name'
        },
        {
          name: 'Nome da Oferta',
          column: 'campaign_name'
        },
        {
          name: 'Resgates',
          column: 'total_redemptions'
        }
      ]
    }
  },
  watch: {
    reloadDataQuery (newval) {
      if (newval) {
        this.loadGraphs()
      }
    }
  },
  mounted () {
    this.loadGraphs()
  },
  methods: {
    ...mapActions({
      getEngagementData: PRODUCTS_DASH_ENGAGEMENT_BAR_DATA,
      getEngagementTable: PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA
    }),
    async loadGraphs () {
      this.isEngagementGraphLoading = true
      const response = await this.getEngagementData()
      const responseTable = await this.getEngagementTable()
      if (response && response.success) {
        this.hasEngagementGraphError = false
        this.loadData(response.data)
      } else {
        this.hasEngagementGraphError = true
      }
      if (responseTable && responseTable.success) {
        this.hasEngagementTableError = false
        this.loadData2(responseTable.data)
      } else {
        this.hasEngagementTableError = true
      }
      this.isEngagementGraphLoading = false
    },
    loadData (data1) {
      this.engagementColumns = [data1.columns]
      this.engagementGraphTabs[0] = data1.tabs
      this.engagementNames = { ...data1.names }
    },
    loadData2 (data2) {
      this.engagementGraphTabs[1] = data2.tabs
      this.engagementTableResults = data2.data
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
