<template>
  <table
    class="table"
  >
    <thead class="head">
      <th
        class="head-item"
        v-for="(label) in labels"
        :key="label.column"
      >
        {{ label.name }}
      </th>
    </thead>
    <tbody
      class="body"
    >
      <tr
        class="row"
        v-for="index in 5"
        :key="`dash-table-${index}`"
      >
        <td
          v-for="(label) in labels"
          :key="`${label.column}-${index}`"
          class="column"
        >
          <loading-bar />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'

export default {
  name: 'LoadingTable',
  components: {
    LoadingBar
  },
  props: {
    labels: Array
  }
}
</script>

<style lang='scss' scoped>
</style>
