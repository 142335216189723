<template>
  <div class="highlight-campaigns-card">
    <div class="highlight-campaigns-tabs">
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 0 ? 'selected-tab' : ''"
        title="Ofertas Destaque"
        :data="tabs"
        :loading="loading"
        :info="highlightInfo"
        icon="info-information-circle"
        @click.native="changeTab(0)"
      />
      <partner-card
        v-if="campaign"
        class="card"
        :class="selectedTab === 1 ? 'selected-tab' : ''"
        :partner="formatedPartner"
        :loading="loading"
        @click.native="changeTab(1)"
      />
      <div
        class="card no-partner"
        v-else
      >
        <p>Clique em uma oferta para visualizar dados</p>
      </div>
    </div>
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
      >
        <loading-card v-if="loading" />
        <div
          v-else
          class="highlight-campaigns-content"
        >
          <campaign-filters
            class="campaign-filters-card"
            :title="'Relatório de Ofertas Exclusivas'"
            :radio-group-name="'highlight'"
            :options="filterOptions"
            :radio-options="highlightFilterOptions"
          />
          <dash-table
            class="campaigns-table-card"
            :title="'Principais Destaques'"
            @select-row="selectCampaign"
          />
          <spine-chart
            class="highlight-campaign-spine-chart"
            v-if="selectedColumns.length > 0"
            :columns="selectedColumns"
            title="Acessos/Resgates"
            :labels="labels"
          />
        </div>
      </transition>
      <div
        v-if="!loading && columns.length === 0"
        class="not-available"
      >
        <not-available />
      </div>
    </div>
  </div>
</template>

<script>
import DashTable from '@/components/Dashboard/base/DashTable.vue'
import SpineChart from '@/components/Dashboard/base/SpineChart.vue'
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import PartnerCard from '@/components/Dashboard/cards/PartnerCard.vue'
import CampaignFilters from '@/components/Dashboard/base/CampaignFilters.vue'
import LoadingCard from '@/components/Dashboard/loading/LoadingCard.vue'

export default {
  name: 'HighlightCampaignsCard',
  components: {
    DashTable,
    SpineChart,
    CampaignsBarCard,
    PartnerCard,
    CampaignFilters,
    LoadingCard
  },
  data () {
    return {
      campaign: null,
      loading: false,
      highlightFilterOptions: [
        {
          label: 'Nome de Oferta',
          value: 'campaign'
        },
        {
          label: 'Nome de Parceria',
          value: 'partner'
        }
      ],
      highlightInfo: {
        title: 'Ofertas em Destaque',
        text: 'Ofertas em destaque'
      },
      filterOptions: [
        {
          label: 'Todos os tipos de oferta',
          value: ''
        },
        {
          label: 'Código Único',
          value: 'unique'
        },
        {
          label: 'Info',
          value: 'info'
        },
        {
          label: 'Online',
          value: 'online'
        },
        {
          label: 'Voucher',
          value: 'voucher'
        }
      ],
      partner: {
        logo: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/hIntox2ZXYarDn2n.png',
        name: 'Steam',
        description: 'Compre Gift Card da Steam e receba Cashback'
      },
      tabs: [
        {
          total: 4000,
          label: 'Acessos em Destaques no período',
          comparisonValue: 10,
          comparisonLabel: 'comparação com o período anterior'
        },
        {
          total: 4000,
          label: 'Resgates em Destaques no período',
          comparisonValue: -10,
          comparisonLabel: 'comparação com o período anterior'
        }
      ],
      selectedTab: 0,
      categorias: ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
      columns: [
        [
          ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
          ['data1', 125, 75, 75, 125, 150],
          ['data2', 220, 180, 225, 200, 175]
        ],
        [
          ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05', '2013-01-06'],
          ['data1', 200, 100, 175, 155, 160],
          ['data2', 150, 350, 200, 200, 175]
        ]
      ],
      labels: [
        {
          id: 'data1',
          label: 'Acessos',
          color: '#202950',
          style: 'solid'
        },
        {
          id: 'data2',
          label: 'Resgates',
          color: '#00B2E3',
          style: 'dashed'
        }
      ],
      tableLabels: ['Nome Oferta', 'Acessos', 'Resgates']
    }
  },
  computed: {
    selectedColumns () {
      return this.columns[this.selectedTab]
    },
    formatedPartner () {
      if (!this.campaign) return null
      return {
        ...this.campaign.partner,
        description: this.campaign.offer
      }
    }
  },
  methods: {
    changeTab (index) {
      this.loading = true
      this.selectedTab = index
      setTimeout(() => {
        this.loading = false
      }, 5000)
      // Inserir chamada para dados da aba aqui
      this.loading = false
    },
    selectCampaign (campaign) {
      this.campaign = campaign
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.highlight-campaigns-card {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));

  > .highlight-campaigns-tabs {
    display: flex;
    z-index: -1;

    > .partner-card {
      justify-content: center;
      box-shadow: none !important;

      & :deep .logo {
        max-height: 50px;
      }
    }

    > .card {
      cursor: pointer;
      background: #F8F9FD;
      padding: 15px 20px;
      border-radius: 6px 6px 0px 0px;
      width: 100%;
      height: 200px !important;
      transition: all ease 0.3s;

      &:not(.selected-tab) {
        &:hover {
          opacity: 0.7;
        }
      }
    }

     > .card:last-child {
      margin-left: 10px;
    }

    & .no-partner {
      background: #F8F9FD;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      p {
        max-width: 180px;
      }
    }

    > .selected-tab {
      background: #FDFDFD;
    }
  }
  .container {
    background: #FDFDFD;
  }

  .highlight-campaigns-content {
    margin-top: -1px;
    padding-top: 15px;
    background: #FDFDFD;
    z-index: 3;
    display: grid;
    grid-template-areas:
      "filters filters filters"
      "table chart chart";
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 6px 6px;

    @include mobile {
      grid-template-areas:
      "filters filters filters"
      "table table table"
      "chart chart chart";
    }

    > .campaign-filters-card {
      grid-area: filters;
      width: 100%;
      padding: 8px 20px;
    }

    > .campaigns-table-card {
      grid-area: table;
    }
    .highlight-campaign-spine-chart {
      grid-area: chart;
      padding: 30px 24px 20px 10px;
      max-width: 700px;
      width: 100%;
    }

    > .graph-card {
      width: 100%;
      min-width: 600px;
      box-shadow: none;

      & :deep .main-card {
        box-shadow: none;
      }

    > .highlight-campaigns-chart-card {
      grid-area: chart;
      padding: 30px 10px 20px 24px;
      max-width: 700px;
      width: 100%;
      @include tablet () {
        max-width: 100%;
      }
    }

    }
  }
}
</style>
