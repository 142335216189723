<template>
  <c-card :class="classes">
    <highlight-tag
      class="tag"
      :discount-type="symbol === '%' ? 'percentage' : 'currency'"
      :discount-value="value"
      :discount-symbol="symbol"
      :is-exclusive="isExclusive"
      :invert="isSelected"
    />

    <div
      class="drag-handle"
      v-if="isDesktop"
    >
      <img src="~/assets/img/drag-icon.svg" />
    </div>

    <div class="content">
      <div class="section-left">
        <c-image
          class="partner-logo"
          :src="image || imagePlaceholder"
          :alt="partner"
        />
        <span
          :class="partnerSpanClasses"
          v-if="!isDesktop"
        >
          {{ status ? 'Ativo' : 'Inativo' }}
        </span>
      </div>

      <div class="section-right">
        <div class="description">
          <h4 class="partner-name">
            {{ partner }}
          </h4>
          <p class="title">
            {{ title }}
          </p>
        </div>

        <p
          class="expiry"
          v-if="expirationDate"
        >
          <c-icon
            size="14"
            icon="clock"
          />
          {{ expirationDate }}
        </p>

        <div class="section-actions">
          <c-button
            v-if="!isDesktop && add"
            :icon="isSelected ? 'check-circle' : 'plus-circle'"
            :alternative="isSelected"
            @click="$emit('action', isSelected)"
            class="add"
            size="md"
          >
            {{ isSelected ? 'Remover' : 'Adicionar' }}
          </c-button>
          <c-button
            v-if="!isDesktop && remove"
            @click="$emit('remove-item')"
            class="remove"
            icon="cross"
            size="md"
          >
            Remover
          </c-button>

          <span
            :class="[ 'status-tag', { '-active': status, '-inactive': !status, '-selected': isSelected } ]"
            v-if="isDesktop"
          >
            {{ status ? 'Ativo' : 'Inativo' }}
          </span>
        </div>
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import CImage from '@/components/CComponents/CImage'

import HighlightTag from '@/components/Communication/Highlight/HighlightTag'

import { getDiff, DAY } from '@/modules/time'

export default {
  components: {
    HighlightTag,
    CCard,
    CImage
  },

  props: {
    title: {
      type: String,
      required: true
    },

    slug: {
      type: String,
      required: true
    },

    image: String,

    expiryDate: {
      type: Number,
      required: true
    },

    partner: {
      type: String,
      required: true
    },

    isExclusive: {
      type: Boolean,
      required: true
    },

    symbol: {
      type: String,
      required: true
    },

    value: {
      type: Number,
      default: 0
    },

    remove: {
      type: Boolean,
      default: false
    },

    add: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    status: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      expiry: null,
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg')
    }
  },
  computed: {
    classes () {
      return [
        'highlight-card', {
          '-exclusive': this.isExclusive,
          '-selected': this.isSelected
        }]
    },

    partnerSpanClasses () {
      return [
        'status-tag', {
          '-active': this.status,
          '-inactive': !this.status,
          '-selected': this.isSelected
        }]
    },

    expirationDate () {
      if (!this.expiryDate || !this.expiry) return undefined

      if (this.expiry <= 3 && this.expiry >= 0) {
        return `Expira em ${this.expiry} dias`
      } else {
        return `${this.isDesktop ? 'Válido at' : 'At'}é ${new Date(this.expiryDate * 1000).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })}`
      }
    }
  },

  mounted () {
    this.getExpiry()
  },

  methods: {
    getExpiry () {
      if (!this.expiryDate) return
      const days = getDiff(this.expiryDate * 1000, new Date(), DAY)
      this.expiry = days
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$partner-name-color: rgba(#121E48, 0.5);
$campaign-title-color: rgba(#121E48, 0.8);

.highlight-card {
  position: relative;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  min-height: 160px;
  height: 100%;

  @include hoverCard;

  & > .tag {
    position: absolute;
    top: 0;
    left: 96px;
    transform: translateX(-50%);
  }

  & > .content {
    display: flex;
    width: 100%;
  }

  & > .drag-handle {
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    width: 15px;
    opacity: 0.6;
  }

  .section-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    margin-top: 19px;
    padding: 0 0 0 10px;
    width: 140px;

    @include desktop { padding: 0 30px; }

    .partner-logo {
      max-width: 80px;
      max-height: 135px;
    }

    & > .status-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-top: 10px;

      text-transform: uppercase;
      font-size: 11px;
      color: white;

      padding: 5px 0;
      width: 90px;

      &:before {
        content: '';
        position: relative;
        margin-right: 5px;
        height: 10px;
        width: 10px;
        background: $success-color;
        border-radius: 20px;
      }

      &.-active {
        color: $success-color;
        background-color: rgba($base-color, 0.14);
        font-weight: bold;
      }

      &.-inactive {
        background-color: rgba($base-color, 0.14);
        color: $failure-color;
        font-weight: 600;

        &::before {
          background-color: $failure-color;
        }
      }

      &.-selected {
        color: #fff;

         &::before {
           background: #fff;
         }
      }
    }
  }

  .section-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 25px 10px 10px 10px;
    width: calc(100% - 140px);

    .description {
      margin-top: 10px;

      .partner-name {
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100%);
        text-overflow: ellipsis ;

        font-size: 14px;
        color: $partner-name-color;
        font-weight: 400;

        margin-bottom: 3px;
      }

      .title {
        color: $campaign-title-color;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include mobile {
          font-size: 12px !important;
          height: 35px;
          margin: 0 auto;
        }
      }
    }

    .expiry {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;

      line-height: 1.5;
      margin: auto 0 10px;

      .c-icon { margin-right: 5px; }
    }

    .section-actions {
      & > .add {
        @media only screen and (max-width: 360px) {
          max-width: 130px;
        }
      }
      & > .status-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        text-transform: uppercase;
        font-size: 11px;
        color: white;

        padding: 5px 0;
        width: 90px;

        &.-active { background-color: $success-color; }

        &.-inactive {
          background-color: rgba($base-color, 0.14);
          color: $text-color;
          font-weight: 600;
        }
      }
    }

    .card-controls {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
    }

    & > .remove {
      max-width: 120px;
      margin-top: 5px;
    }
  }

  &.-exclusive > .content > .section-left {
     margin-top: 19px;
     padding: 0 0 0 10px;

    @include desktop {
      padding: 0 30px;
    }
  }

  &.-selected {
    background-color: $primary-color-placeholder;
    background-color: var(--color-primary);

    .partner-name { color: #FFF !important; font-weight: 200 !important; }

    .title {
      color: #FFF !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
</style>
