<template>
  <footer class="layout-footer">
    <div class="links">
      <div class="content">
        <component
          v-for="link in links"
          :key="link.name"
          class="link"
          :href="link.external && link.path"
          :target="link.external ? '_blank' : ''"
          :is="link.external ? 'a' : 'router-link'"
          :to="link.path"
        >
          {{ link.name }}
        </component>
      </div>
    </div>

    <div class="bottom">
      <div class="content">
        <div class="copyright">
          <div class="logo">
            <c-logo
              is-grey
              kaledo
              :kaledo-height="28"
            />
          </div>
          <p>&copy; {{ new Date().getFullYear() }}</p>
        </div>
      </div>
    </div>

    <c-button
      :class="{ '-shown': displayScrollTop }"
      @click="scrollTop"
      alternative
      class="top"
      size="xl"
      icon="chevron-up"
    />
  </footer>
</template>

<script>
import CLogo from '@/components/CComponents/CLogo'

export default {
  components: { CLogo },
  props: {
    displayScrollTop: Boolean,
    personalization: Object
  },
  computed: {
    links () {
      return [
        {
          name: 'Suporte',
          path: this.personalization && this.personalization.config && (this.personalization.config.support_link || 'https://convenia.kayako.com/'),
          external: true
        }
      ]
    }
  },
  methods: {
    scrollTop () {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.layout-footer {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 16px;
  flex-shrink: 0;

  margin-top: 75px;

  @include mobile { padding-bottom: 55px; }

  .links {
    background-color: $primary-color-placeholder;
    background-color: var(--color-primary);
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;

    & > .content {
      @include desktop { @include container }
    }

    .link {
      color: white;
      text-decoration: none;
      font-size: 12px;
      position: relative;

      &:not(:last-of-type) {
        margin-right: 21px;

        &::after {
          content: '';
          position: absolute;
          right: -10px;
          top: 10%;
          height: 80%;
          border-right: 1px solid rgba(white, 0.3);
        }
      }
    }
  }

  & > .bottom {
    padding-left: 20px;
    padding-right: 20px;
    background-color: $background-color;

    & > .content {
      @include desktop { @include container }
    }

    .copyright {
      padding-top: 20px;
      padding-bottom: 20px;
      color: rgba(17.85, 30.6, 71.4, 0.5);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 11px;
      line-height: 17px;
      border-top: 1px solid $border-color;

      & > .logo {
        margin-right: 7px;

        & > .c-logo { width: 75px;}
      }
    }
  }

  & > .top {
    position: fixed;
    right: 20px;
    bottom: -48px;
    transition: bottom 0.3s ease;

    &.-shown {
      bottom: 75px;
      z-index: 99;

      @include desktop { bottom: 20px; }
    }
  }
}
</style>
