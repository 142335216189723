<template>
  <div class="offers-info">
    <div class="main">
      <loading-bar v-if="loading" />
      <h2
        v-else
        class="total"
      >
        250
      </h2>
      <div class="title">
        <h4>
          Ofertas ativas<br />no período
        </h4>
        <info-button
          icon="info-information-circle"
          class="icon"
          :info="info"
        />
      </div>
      <comparation
        :total-comparison="10"
        :loading="loading"
      />
    </div>
    <div class="card-1">
      <mini-card
        v-for="(card, index) in cards"
        class="card"
        :key="`offers-info-card-${index}`"
        :total="card.total"
        :label="card.label"
        :icon="card.icon"
        :format-label="true"
        :loading="loading"
      >
        <comparation
          :total-comparison="card.comparison"
          :loading="loading"
        />
      </mini-card>
    </div>
    <div class="card-2">
      <mini-card
        class="card"
        :total="finalCard.total"
        :label="finalCard.label"
        :icon="finalCard.icon"
        :loading="loading"
        :format-label="true"
      >
        <comparation
          :total-comparison="finalCard.comparison"
          :loading="loading"
        />
      </mini-card>
    </div>
  </div>
</template>

<script>
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'
import Comparation from '@/components/Dashboard/base/Comparation.vue'
import MiniCard from '@/components/Dashboard/cards/MiniCard.vue'
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'

export default {
  name: 'OffersInfo',
  components: {
    InfoButton,
    Comparation,
    MiniCard,
    LoadingBar
  },
  props: {
    loading: Boolean
  },
  data () {
    return {
      info: {
        title: 'Ofertas ativas no período',
        text: 'Ofertas ativas no período'
      },
      cards: [
        {
          total: 120,
          label: 'Ofertas exclusivas ativas no período',
          icon: 'star-check-mark',
          comparison: 10
        },
        {
          total: 50,
          label: 'Ofertas destaque ativas no período',
          icon: 'crown-circle',
          comparison: -10
        }
      ],
      finalCard: {
        total: 130,
        label: 'Ofertas gerais ativas no período',
        icon: 'favorite',
        comparison: null
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.offers-info {
  margin-top: 30px;
  margin-bottom: 24px;
  background: #FDFDFD;
  width: 100%;
  height: 185px;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 6px;

  display: flex;
  @include mobile {
    height: auto;
    flex-wrap: wrap;
  }

  .main {
    max-width: 220px;
    margin-right: 10px;
    .loading-bar {
      height: 26px;
      margin-bottom: 22px;
      margin-top: 6px;
    }
    .total {
      font-size: 40px;
      line-height: 26px;
      font-weight: 700;
      color: $base-color;
      margin-bottom: 22px;
      margin-top: 6px;
    }
    .title {
      display: flex;
      margin-right: 20px;
      justify-content: space-between;
      > h4 {
        font-weight: 400;
        color: $base-color;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
      }
    }
    @include mobile {
      max-width: 100%;
    }
  }
  .card-1, .card-2 {
    border: 1px solid #BEC0D3;
    border-radius: 4px;
    padding: 16px;
  }
  .card-1 {
    display: flex;
    max-width: 474px;
    align-items: flex-start;
    .card + .card {
      margin-left: 10px;
    }
    @include mobile {
      flex-wrap: wrap;
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      .card {
        width: 100%;
      }
      .card + .card {
        margin-top: 20px;
        margin-left: 0px;
      }
    }
  }
  .card-2 {
    width: 270px;
    margin-left: 10px;
    @include mobile {
      width: 100%;
      margin-left: 0px;
    }
  }
}
</style>
