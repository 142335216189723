<template>
  <div class="initial-card">
    <div class="first-line">
      <div
        class="users-content"
        v-if="currentTab === 'dashboard-communication-tab'"
      >
        <mini-card
          v-for="(card, index) in communicationMiniCards"
          :key="`mini-card-${index}`"
          class="card"
          :total="`${card.total}`"
          :label="card.label"
          :sub-title="card.subTitle"
          no-icon
          :loading="loading"
          :show-zero="true"
          :word-break="false"
        />
      </div>
      <div
        class="users-content"
        v-else-if="currentTab !== 'dashboard-products-tab'"
      >
        <mini-card
          v-for="(card, index) in miniCards"
          :key="`mini-card-${index}`"
          class="card"
          :total="`${card.total}`"
          :label="card.label"
          no-icon
          :loading="loading"
          :word-break="false"
        />
      </div>
      <div
        class="products-content"
        v-if="currentTab === 'dashboard-products-tab'"
      >
        <p class="wallet-text">
          Saldo em carteira
          <info-button
            icon="info-information-circle"
            class="info-wallet"
            :info="infoWallet"
          />
        </p>
        <transition name="fade">
          <loading-bar
            v-if="loadingBalance"
            width="181px"
            height="44px"
          />
          <h2
            v-else
            class="wallet-value"
            data-cy="wallet-value"
          >
            {{ walletValue }}
          </h2>
        </transition>
      </div>
      <div
        :class="currentTab === 'dashboard-communication-tab' ?
          'time-period time-period-communication-tab':
          'time-period'"
      >
        <div class="period">
          <c-select
            form-label="Período"
            class="discounts-item"
            track-by="value"
            display-by="label"
            :items="datesOptions"
            :value="selectValue"
            :disabled="disableFunctions"
            @input="selectTimePeriod"
          />
        </div>
        <div
          v-if="currentTab !== 'dashboard-products-tab'"
          class="date"
        >
          <c-input
            class="form-control -inline -no-margin"
            icon="calendar"
            ref="datePeriod"
            validation-icon="warning-circle"
            :placeholder="datePlaceholder"
            auto
            required
            label=""
            name="period"
            autocomplete="off"
            mask="##/##/#### - ##/##/####"
            watch
            v-validate="'required'"
            :feedback-show="errors.has('period')"
            :feedback-message="errors.first('period')"
            :flex="true"
            :value="period"
            :disabled="disableFunctions"
            @input="period = $event"
            @click.native="openPopover"
          />
        </div>

        <c-popover
          v-if="showDatePicker"
          :position="popoverPosition"
          :target="$refs.datePeriod"
          @blur="closePopover"
        >
          <div
            class="datepickers"
            @click.stop
          >
            <functional-calendar
              ref="fcalendar"
              class="initial-calendar"
              @input="handleCalendar"
              :limits="limits"
              :day-names="calendarOptions.dayNames"
              :month-names="calendarOptions.monthNames"
              :short-month-names="calendarOptions.shortMonthNames"
              :disabled-dates="disabledDates"
              is-date-range
              is-multiple
              :calendars-count="2"
            />
            <div class="buttons">
              <c-button
                class="datepicker-button"
                size="lg"
                @click="closePopover"
              >
                Cancelar
              </c-button>
              <c-button
                class="datepicker-button"
                size="lg"
                @click="applyData"
              >
                Aplicar
              </c-button>
            </div>
          </div>
        </c-popover>
        <c-button
          v-if="currentTab === 'dashboard-communication-tab'"
          class="apply-button"
          primary
          size="lg"
          :disabled="disableApplyButton || !(start_date && end_date) "
          @click="submitData"
        >
          Aplicar
        </c-button>
      </div>
    </div>
    <div class="second-line">
      <radio-buttons
        v-if="$can('compare-with-last-period')"
        class="radio-buttons"
        :options="radioButtons"
        :selected-value.sync="radioOption"
        :disabled="disableFunctions"
      />
      <c-button
        v-if="currentTab !== 'dashboard-communication-tab'"
        class="apply-button"
        primary
        size="lg"
        :disabled="disableApplyButton || !(start_date && end_date) "
        @click="submitData"
      >
        Aplicar
      </c-button>
    </div>
  </div>
</template>

<script>
import { ptBR } from 'vuejs-datepicker/dist/locale'
import predefinedDates from '@/content/dashPredefinedDates'
import moment from 'moment'
import MiniCard from './MiniCard.vue'
import CPopover from '@/components/CComponents/CPopover'
import RadioButtons from '@/components/Dashboard/base/RadioButtons.vue'
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'

import dashDatesOptions from '@/content/dashDatesOptions'
import { FunctionalCalendar } from 'vue-functional-calendar'
import { WALLET_BALANCE } from '@/store/types'
import { mapActions } from 'vuex'

export default {
  name: 'InitialCard',
  components: {
    MiniCard,
    CPopover,
    RadioButtons,
    FunctionalCalendar,
    InfoButton,
    LoadingBar
  },
  props: {
    disableFunctions: Boolean,
    loading: Boolean,
    graphData: Object,
    initialDate: Object,
    currentTab: String
  },
  data () {
    return {
      ptBR,
      loadingBalance: false,
      period: '',
      auxData: {},
      start_date: null,
      end_date: null,
      showDatePicker: false,
      errorMessage: '',
      selectValue: 'yesterday',
      radioOption: 'use-last-period',
      walletValue: 'R$ 0,00',
      radioButtons: [
        {
          label: 'Comparar com o periodo anterior',
          value: 'use-last-period'
        },
        {
          label: 'Comparar com mesmo período do ano passado',
          value: 'use-last-year'
        }
      ]
    }
  },
  computed: {
    miniCards () {
      return [
        {
          total: this.graphData.activeUsers || '-',
          label: 'Total de usuários ativos no seu clube',
          icon: 'user-cursor-circle1'
        },
        {
          total: this.graphData.enabledUsers || '-',
          label: 'Total de usuários habilitados no seu clube',
          icon: 'user-clock'
        }
      ]
    },
    communicationMiniCards () {
      return [
        {
          total: this.graphData.active_banners,
          subTitle: 'Banners exclusivos',
          label: 'Ativos no momento',
          icon: ''
        },
        {
          total: this.graphData.active_notifications,
          subTitle: 'Notificações exclusivas',
          label: 'Ativos no momento',
          icon: ''
        }
      ]
    },
    calendarOptions () {
      return {
        dayNames: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      }
    },
    limits () {
      const activeDate = moment(this.graphData.activeDate, 'DD-MM-YYYY')
      const diffDays = moment().diff(activeDate, 'days')
      const min = diffDays > 365
        ? moment().subtract(12, 'months').format('DD/MM/YYYY')
        : moment().subtract(parseInt(diffDays / 2), 'days').format('DD/MM/YYYY')
      const max = moment().format('DD/MM/YYYY')
      return { min, max }
    },
    disabledDates () {
      const today = moment(new Date()).format('DD/MM/YYYY')
      return [today, 'afterToday']
    },
    popoverPosition () {
      if (this.isLgScreen) return ['top', 'right']
      return ['top', 'center']
    },
    disableApplyButton () {
      if (this.currentTab === 'dashboard-communication-tab') return false
      if (!this.start_date || !this.end_date) return true
      return !(this.start_date.length > 0 && this.end_date.length > 0)
    },
    datePlaceholder () {
      const periodValue = predefinedDates(this.graphData.activeDate, this.selectValue)
      return `${periodValue[0]} - ${periodValue[1]}`
    },
    datesOptions () {
      return dashDatesOptions(this.graphData.activeDate, this.currentTab)
    },
    infoWallet () {
      return {
        title: 'Saldo em carteira',
        text: 'Saldo total disponível na carteira'
      }
    }
  },
  watch: {
    selectValue () {
      const dateValues = predefinedDates(this.graphData.activeDate, this.selectValue)
      this.start_date = dateValues[0]
      this.end_date = dateValues[1]
      this.period = `${this.start_date}${this.end_date}`
    },
    currentTab (newval) {
      if (newval === 'dashboard-products-tab') {
        this.loadBalance()
        this.selectValue = 'last-30-days'
        this.selectAndSubmitData()
      } else if (newval === 'dashboard-users-tab') {
        this.selectValue = 'yesterday'
        this.selectAndSubmitData()
      } else if (newval === 'dashboard-communication-tab') {
        this.selectValue = 'last-30-days'
        this.selectAndSubmitData()
      }
    }
  },
  mounted () {
    this.start_date = moment(this.initialDate.start_date).format('DD/MM/YYYY')
    this.end_date = moment(this.initialDate.end_date).format('DD/MM/YYYY')
    this.period = `${this.start_date}${this.end_date}`
  },
  methods: {
    ...mapActions({
      getWalletBalance: WALLET_BALANCE
    }),
    selectAndSubmitData () {
      const dateValues = predefinedDates(this.graphData.activeDate, this.selectValue)
      this.start_date = dateValues[0]
      this.end_date = dateValues[1]
      this.period = `${this.start_date}${this.end_date}`
      this.submitData()
    },
    submitData () {
      this.$emit('submit', {
        start_date: moment(this.formatDate(this.start_date)).format('YYYY-MM-DD'),
        end_date: moment(this.formatDate(this.end_date)).format('YYYY-MM-DD')
      })
    },
    formatDate (date) {
      return `${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`
    },
    handleCalendar (event) {
      this.auxData = event
    },
    applyData () {
      this.closePopover()
      this.start_date = this.auxData.dateRange.start
      this.end_date = this.auxData.dateRange.end
      this.formatPeriod(this.auxData.dateRange.start, this.auxData.dateRange.end)
    },
    formatDateNumber (number) {
      if (parseInt(number) < 10) return `0${number}`
      return number
    },
    formatPeriod (start, end) {
      const splittedStart = start.split('/')
      const splittedEnd = end.split('/')
      const formatedStart = `${this.formatDateNumber(splittedStart[0])}/${this.formatDateNumber(splittedStart[1])}/${splittedStart[2]}`
      const formatedEnd = `${this.formatDateNumber(splittedEnd[0])}/${this.formatDateNumber(splittedEnd[1])}/${splittedEnd[2]}`
      this.period = `${formatedStart}${formatedEnd}`
    },
    openPopover () {
      this.showDatePicker = true
    },
    closePopover () {
      this.showDatePicker = false
    },
    selectTimePeriod (value) {
      this.selectValue = value
      this.$emit('symbol', value)
    },
    setHighlightedDates (date) {
      const dateFormated = moment(date).format('YYYY-MM-DD') + 'T00:00:00.000Z'
      if (!this.highlighted.to) {
        this.highlighted.to = dateFormated
      } else {
        this.highlighted.from = dateFormated
      }
    },
    toMoney (value) {
      return parseFloat(value).toFixed(2).replace('.', ',')
    },
    async loadBalance () {
      this.loadingBalance = true
      const response = await this.getWalletBalance()
      if (response && response.success) {
        this.walletValue = `R$ ${this.toMoney(response.data / 100)}`
      }
      this.loadingBalance = false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.initial-card {
  width: 100%;
  background: white;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  .first-line, .second-line {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  .second-line {
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 12px;
    .radio-buttons {
      margin-right: 30px;
    }
    .apply-button {
      width: 115px;
      height: 32px;
      @include mobile {
        width: 100%;
        margin-top: 20px;

      }
    }
    @include mobile {
      width: 100%;
      align-items: flex-start;
      margin-top: 20px;
      .radio-buttons {
        margin-right: 0px;
        width: 100%;
      }
    }
  }
  .users-content {
    display: flex;
  }
  .products-content {
    .wallet-text {
      display: flex;
      align-items: center;
      color: $base-color;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      .info-wallet {
        margin-left: 5px;
      }
    }
    .wallet-value {
      color: $base-color;
      font-size: 32px;
      font-weight: 700;
    }
  }
  .card {
    max-width: 216px;
    padding-top: 20px;
    @include mobile {
      max-width: 100%;
      width: 100%;
    }
  }
  .card + .card {
    margin-left: 25px;
    @include mobile {
      margin-left: 0px;
    }
  }
  .time-period-communication-tab {
    width: 589px !important;
    align-items: flex-end !important;
    margin-bottom: 12px;
    margin-left: 0 !important;

    .apply-button {
      margin-left: 16px;
    }
  }
  .time-period {
    margin-left: 114px;
    width: 420px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    .period {
      width: 205px;
      margin-right: 10px;
      z-index: 4;
    }
    .date {
      width: 205px;
      margin-top: 25px;
    }
    @include mobile {
      margin-left: 0;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 20px;
      .period {
        width: 100%;
        margin-right: 0px;
      }
      .date {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  .datepickers {
    // display: flex;
    background: white;
    padding: 25px 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 6px;

    .buttons {
      text-align: right;
      position: relative;
      .datepicker-button {
        height: 32px;
        width: 115px;
      }
      .datepicker-button + .datepicker-button {
        margin-left: 16px;
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@/styles/reference.scss';
.datepicker {
  .vdp-datepicker__calendar {
    border: none;
    padding: 0 20px;
  }
  &.start-date {
    border-right: 0.5px solid #BEC0D3;
  }
  .vdp-datepicker__calendar .cell.selected {
    background: $base-color;
    color: white;
    border-radius: 50%;
  }
}
.initial-calendar {
  .vfc-main-container {
    box-shadow: none;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-today {
    background-color: $hover-background-color;
    color: $c-color;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: #00B2E3;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked:before, .vfc-week .vfc-day .vfc-base-start, .vfc-week .vfc-day .vfc-base-end {
    background-color: #D8E0F6;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .initial-calendar .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: #00B2E3;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-borderd:before, .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-start-marked:before, .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-end-marked:before {
    background-color: $base-color;
    border-radius: 50%;
  }
  .vfc-week .vfc-day span.vfc-span-day,
  .vfc-top-date span,
  .vfc-dayNames span {
    color: #5E6684;
  }
  .vfc-navigation-buttons div .vfc-arrow-left, .vfc-separately-navigation-buttons div .vfc-arrow-left,
  .vfc-navigation-buttons div .vfc-arrow-right, .vfc-separately-navigation-buttons div .vfc-arrow-right {
    border-color: #5E6684;
  }

}
</style>
