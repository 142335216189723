<template>
  <initial-card
    @submit="dates => handleSubmit(dates)"
    :loading="loading"
    :error="error"
    :initial-date="dashDates"
    :graph-data="graphData"
    :format-tab-label="true"
    :current-tab="currentTab"
  />
</template>

<script>
import InitialCard from '@/components/Dashboard/cards/InitialCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_ACTIVE_ENABLED_USERS, DASH_DATES, DASH_COMMUNICATION_RESUME } from '@/store/types'
export default {
  name: 'UserActivation',
  components: {
    InitialCard
  },
  props: {
    reloadDataQuery: Boolean,
    currentTab: String
  },
  data () {
    return {
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      activeAndEnabledUsersData: DASH_ACTIVE_ENABLED_USERS,
      communicationResumeData: DASH_COMMUNICATION_RESUME,
      dashDates: DASH_DATES
    }),

    graphData () {
      if (this.currentTab === 'dashboard-communication-tab') {
        return this.communicationResumeData
      } else {
        return this.activeAndEnabledUsersData
      }
    }
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUsersActivated() }
    }
  },
  mounted () {
    this.getUsersActivated()
  },
  methods: {
    ...mapActions({
      getActiveAndEnabledUsersData: DASH_ACTIVE_ENABLED_USERS,
      getCommunicationResume: DASH_COMMUNICATION_RESUME
    }),
    handleSubmit (dates) {
      this.$emit('submit', dates)
    },
    async getUsersActivated () {
      this.loading = true
      if (this.currentTab === 'dashboard-communication-tab') {
        await this.getCommunicationResume().then((success) => {
          this.error = !success
          this.loading = false
        })
      } else {
        this.getActiveAndEnabledUsersData().then((success) => {
          this.error = !success
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
</style>
