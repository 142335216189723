import { render, staticRenderFns } from "./DashboardProductsTab.vue?vue&type=template&id=5c703a27&scoped=true"
import script from "./DashboardProductsTab.vue?vue&type=script&lang=js"
export * from "./DashboardProductsTab.vue?vue&type=script&lang=js"
import style0 from "./DashboardProductsTab.vue?vue&type=style&index=0&id=5c703a27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c703a27",
  null
  
)

export default component.exports