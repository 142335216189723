<template>
  <div class="loading-gauge">
    <div class="multigraph">
      <span class="graph" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingGauge'
}
</script>

<style lang='scss' scoped>
$circle-width: 194px;
$circle-height: 97px;
.loading-gauge {
  position: relative;
  .multigraph {
    width: $circle-width;
    height: $circle-height;
    &:before {
      content: '';
      width: $circle-width;
      height: $circle-height;
      position: absolute;
      border: 30px solid white;
      border-radius: $circle-width $circle-width 0 0;
      border-bottom: none;
    }
    &:hover .tooltip {
      bottom: 75%;
      opacity: .8;
      transform: scale(1);
    }
  }
  .graph {
    width: $circle-width;
    height: $circle-height;
    position: absolute;
    overflow:hidden;
    &:before, &:after {
      width: $circle-width;
      height: $circle-height;
      position: absolute;
      top: 100%;
      border: 30px solid #f8f8f8;
      content: '';
      border-radius:0 0 $circle-width $circle-width;
      border-top: none;
      animation: demo 3s infinite;
      transform-origin: center top;
    }
    &:after {
      transform: rotate(0deg);
    }
  }
  @keyframes demo {
    0% {
      transform: rotate(0deg);
    }
    8% {
      border-color: #fafafa;
    }
    50% {
      transform: rotate(180deg);
      border-color: #f8f8f8;
    }
  }
}

</style>
