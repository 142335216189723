// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/img/contorno_draggable.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active,.fade-leave-active{transition:opacity .3s}.fade-enter,.fade-enter-from,.fade-leave-to{opacity:0}.slide-fade-enter-active{transition:all .1s ease}.slide-fade-leave-active{transition:all .4s cubic-bezier(1,.5,.8,1)}.slide-fade-enter,.slide-fade-leave-to{transform:translateY(-50px)}.side-toggle{background-color:#f7f8fd;box-shadow:1px 0 13px -2px rgba(0,0,0,.75);height:100%;min-height:100vh;position:fixed;right:0;top:0;transform:translateX(0);transition:transform .3s;width:440px;z-index:22}.side-toggle>.content{height:100%;overflow:auto;position:relative;transition:opacity .3s;width:100%}.side-toggle>.content>.close{position:absolute;right:20px;top:10px;z-index:15}.side-toggle>.side-button{align-items:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100%;background-repeat:no-repeat;background-size:contain;display:flex;height:100px;justify-content:flex-end;left:0;position:absolute;top:50%;transform:translate(-100%,-100%);width:50px}.side-toggle.-closed{transform:translateX(100%)}.side-toggle.-closed>.content{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
