<template>
  <div
    v-if="graphData"
    class="gauge-chart"
  >
    <h3 class="graphic-title">
      {{ graphData.title }}
    </h3>
    <vue-c3
      class="gauge"
      :handler="handler"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default {
  name: 'GaugeChart',
  components: {
    VueC3
  },
  props: {
    graphData: Object
  },
  data () {
    return {
      handler: new Vue()
    }
  },
  computed: {
    formatedColumns () {
      return this.graphData ? [['0', ...this.graphData.columns]] : [[]]
    },
    legendClasses () {
      return this.graphData ? { label: this.graphData.gauge.title, description: this.graphData.gauge.description } : {}
    },
    labels () {
      return this.graphData ? Object.keys(this.graphData.names) : []
    },
    options () {
      return {
        data: {
          columns: this.formatedColumns,
          type: 'gauge',
          classes: this.legendClasses
        },
        size: {
          width: 240,
          height: 150
        },
        legend: {
          show: false
        },
        gauge: {
          label: {
            format: function (value) {
              return `${value}%`
            }
          },
          width: 35
        },
        tooltip: {
          horizontal: false,
          contents: function () {
            const meta = this.config.data_classes

            return `
            <div class="custom-tooltop">
              <h6 class="tooltip-title">${meta.label}</h6>
              <p class="tooltip-value">${meta.description}</p>
            </div>`
          }
        },
        color: {
          pattern: ['#202950']
        }
      }
    }
  },
  mounted () {
    this.handler.$emit('init', this.options)
  },
  methods: {
    mouseEnter (id) {
      this.handler.$emit('dispatch', (chart) => chart.focus(id))
    },
    mouseLeave () {
      this.handler.$emit('dispatch', (chart) => chart.revert())
    },
    handleClick (id) {
      this.handler.$emit('dispatch', (chart) => chart.toggle(id))
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/reference.scss';
  .gauge-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    .graphic-title {
      color: $neutral-dark-blue;
      font-size: 16px;
      font-family: 'Nunito Sans';
      font-weight: 700;
      margin-right: 20px;
    }
  }
</style>
<style lang="scss">
@import '~@/styles/reference.scss';
.gauge {
  margin-top: -25px;
  margin-left: -20px;

  .c3-chart-arcs-gauge-max,
  .c3-chart-arcs-gauge-min {
    display: none;
  }
  .c3-gauge-value {
    fill: $neutral-dark-blue;
    font-size: 18px;
    font-family: 'Nunito Sans';
    font-weight: 700;
  }
  .c3-tooltip-container {
    background: white;
    box-shadow: rgba(0,0,0,0.1);
    border-radius: 3px;
  }
  .custom-tooltop {
    width: 180px;
    min-height: 50px;
    font-size: 12px;
    padding: 8px;
    .tooltip-title {
      color: $title-color;
      font-weight: 600;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .tooltip-value {
      color: #5E6684;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
