<template>
  <div :class="[ 'highlight-list-wrapper', { '-is-opened': isOpened }]">
    <div :class="[ 'highlight-controls', { '-openedheader': openedList } ]">
      <c-title
        class="highlight-title"
        :icon="isDesktop ? 'thumbs-up' : ''"
      >
        {{ isDesktop ? 'Destaques' : 'Adicionar oferta em destaque' }}

        <c-button
          class="button-mobile-close"
          v-if="!isDesktop"
          @click="closeActions"
          size="lg"
          icon="cross"
        />
      </c-title>

      <div
        class="header-actions"
        v-if="isDesktop"
      >
        <div class="wrapper-search-field">
          <search-field
            :term="searchTerm"
            @reset="$emit('update-filters', { name: '' })"
            @input="name => $emit('update-filters', { name })"
            @submit="$emit('search-campaigns')"
            @focusout="handleAnalytics('buscar-oferta-destaque', { company_name: company.name, searched_term: searchTerm})"
            placeholder="Encontre uma oferta"
          />
        </div>
        <div class="media-inline">
          <c-button
            size="lg"
            icon="chevron-down"
            icon-size="sm"
            after
            ref="orderToggle"
            @click.native="showOrder = !showOrder"
          >
            Ordenar
          </c-button>

          <c-button
            size="lg"
            icon="chevron-down"
            after
            icon-size="sm"
            ref="filterToggle"
            @click.native="showFilter = !showFilter"
          >
            Filtrar
          </c-button>
        </div>
      </div>

      <div class="header-popovers">
        <c-popover
          v-if="showOrder"
          :position="['top', 'center']"
          :target="$refs['orderToggle']"
          @blur="showOrder = false"
        >
          <div class="filters">
            <filter-group
              class="popover-filter-group"
              title="Ordenar por"
            >
              <c-button
                size="lg"
                slot="actions"
                @click.native.stop="clean(ordinators)"
              >
                Limpar seleção
              </c-button>
              <filter-toggler
                class="filter"
                v-for="ordinator in ordinators"
                :key="ordinator.name"
                :name="ordinator.name"
                :icon="ordinator.icon"
                :selected="params[ordinator.action]"
                @click.native.stop="handleOrder(ordinator.action)"
              />
            </filter-group>
          </div>

          <c-button
            class="popover-filter-apply"
            icon="chevron-right"
            size="sm"
            primary
            block
            @click="applyOrderFilter()"
          >
            Aplicar
          </c-button>
        </c-popover>

        <c-popover
          v-if="showFilter"
          :position="['top', 'right']"
          :target="$refs['filterToggle']"
          @blur="showFilter = false"
        >
          <div class="filters">
            <filter-group
              class="popover-filter-group"
              title="Por categorias"
            >
              <c-button
                slot="actions"
                size="lg"
                @click.native.stop="clean(categories.concat(status))"
              >
                Limpar seleção
              </c-button>

              <filter-toggler
                class="filter"
                v-for="filter in categories"
                :key="filter.name"
                :name="filter.name"
                :icon="filter.icon"
                :selected="params[filter.action]"
                @click.native.stop="handleCategory(filter.action)"
              />
            </filter-group>
          </div>

          <c-button
            class="popover-filter-apply"
            icon="chevron-right"
            size="sm"
            primary
            block
            @click="applyFilters()"
          >
            Aplicar
          </c-button>
        </c-popover>
      </div>

      <div
        class="wrapper-search-field"
        v-if="!isDesktop"
      >
        <search-field
          :term="searchTerm"
          @reset="resetSearch()"
          @input="name => $emit('update-filters', { name })"
          @submit="$emit('search-campaigns')"
          placeholder="Encontre uma oferta"
        />
      </div>

      <div
        class="wrapper-filter-toggle"
        v-if="!isDesktop"
      >
        <c-button
          after
          size="lg"
          @click.native="$emit('open-filters')"
        >
          Filtrar
        </c-button>
      </div>
    </div>

    <draggable
      element="div"
      :class="[ 'highlight-list', { '-opened': isOpened, '-empty': !campaigns.length } ]"
      v-bind="options"
      @start="onStart"
      @end="onEnd"
      @remove="$emit('remove-item', $event)"
      @add="$emit('add-item', $event)"
    >
      <transition-group
        tag="div"
        type="transition"
        :name="'highlight-list'"
        :class="[ 'content', { '-openedlist': openedList }]"
      >
        <div
          class="item-wrapper"
          v-for="(campaign) in campaigns"
          :key="campaign.id"
        >
          <highlight-card
            :slug="campaign.slug"
            :image="campaign.partner && campaign.partner.logo"
            :title="campaign.title"
            :partner="campaign.partner && campaign.partner.name"
            :expiry-date="campaign.end_date"
            :value="campaign.value | toNumber"
            :symbol="campaign.symbol"
            :status="!!campaign.status"
            :is-exclusive="!!(campaign.partner && campaign.partner.company_id)"
            :add="!isDesktop"
            :is-selected="!isDesktop && selectedCampaigns.includes(campaign.slug)"
            @action="selected => $emit((!selected ? 'remove' : 'add') + '-item', { slug: campaign.slug })"
          />
        </div>

        <c-empty
          v-if="isDesktop && !campaigns.length && !featuredDragging"
          key="empty"
          class="empty"
          character="searching"
          :title="emptyTitle"
          :message="emptyMessage"
        />
      </transition-group>
    </draggable>

    <div
      class="highlight-actions"
      v-if="!isDesktop"
    >
      <div :class="classesWarning">
        <c-icon
          :icon="showWarning.length === 10 ? 'cross-circle2' : 'plus-circle'"
          size="18"
        />
        <span class="text success">
          <span class="count">{{ showWarning.length }}/10 </span>
          <span v-if="showWarning.length === 10"> Ofertas selecionadas</span>
          <span v-else> Ofertas selecionadas</span>
        </span>
      </div>

      <c-button
        @click="saveActions"
        class="featured-save"
        icon="chevron-right"
        size="sm"
        block
      >
        Salvar Destaques
      </c-button>
    </div>

    <div
      :class="[ 'highlight-pagination', { '-openedlist': openedList }]"
      v-if="showPagination || paginationLoading"
    >
      <c-spinner
        v-if="paginationLoading"
        size="lg"
      />
      <c-button
        @click="$emit('paginate-list')"
        v-else-if="showPagination"
        size="lg"
      >
        Ver Mais
      </c-button>
    </div>
  </div>
</template>

<script>
import FilterToggler from '@/components/Filter/FilterToggler'
import FilterGroup from '@/components/Filter/FilterGroup'
import CPopover from '@/components/CComponents/CPopover'
import HighlightCard from '@/components/Communication/Highlight/HighlightCard'
import SearchField from '@/components/CComponents/SearchField'

import ordinators from '@/store/filters/ordinators.json'
import filters from '@/store/filters/filters.json'
import * as object from '@/modules/object'

import Draggable from 'vuedraggable'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: {
    HighlightCard,
    FilterToggler,
    SearchField,
    FilterGroup,
    CPopover,
    Draggable
  },

  filters: {
    toNumber (value) {
      if (typeof value === 'number') {
        return value
      }

      if (typeof value === 'string') {
        return +value.replace(/\D/g, '')
      }
    }
  },

  props: {
    campaigns: {
      type: Array,
      required: true
    },
    searchTerm: {
      type: String,
      required: true
    },
    hasCampaigns: {
      type: Boolean,
      required: true
    },
    featuredDragging: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    openedList: {
      type: Boolean,
      required: true
    },
    isOpened: {
      type: Boolean,
      required: true
    },
    dragOptions: {
      type: Object,
      required: true
    },
    paginationLoading: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      required: true
    },
    selectedCampaigns: {
      type: Array,
      required: true
    },
    showWarning: {
      type: Array,
      required: true
    }
  },

  data () {
    const params = {
      ...this.getActions(filters.categories),
      ...this.getActions(filters.status),
      ...this.getActions(ordinators)
    }

    Object.keys(params).forEach(key => {
      params[key] = this.filters[key] || this.filters.categories.find(c => c === key)
    })

    return {
      showFilter: false,
      showOrder: false,
      categories: [...filters.categories],
      status: [...filters.status],
      ordinators: [...ordinators],
      params
    }
  },

  computed: {
    ...mapGetters({ company: types.COMPANY }),
    classesWarning () {
      const classesWarning = [
        'featured-warning', {
          '-warning': this.showWarning.length === 10
        }
      ]
      return classesWarning
    },

    featuredCampaigns () {
      return this.selectedCampaigns.map((slug, i) => ({ slug, order: i }))
    },

    options () {
      return { ...this.dragOptions, filter: 'c-empty' }
    },

    emptyTitle () {
      return this.hasCampaigns
        ? 'Você adicionou todas as ofertas cadastradas em destaque'
        : 'Você ainda não cadastrou nenhuma oferta exclusiva!'
    },

    emptyMessage () {
      return this.hasCampaigns
        ? 'Para adicionar mais destaques cadastre novas ofertas exclusivas'
        : 'O que acha de adicionar a primeira oferta exclusiva do clube?'
    }
  },
  methods: {
    applyOrderFilter () {
      this.$emit('apply-filters') + (this.showOrder = false)
      this.handleAnalytics('ordenar-oferta-destaque', {
        company_name: this.company.name
      })
    },
    applyFilters () {
      this.$emit('apply-filters') + (this.showFilter = false)
      this.handleAnalytics('filtrar-oferta-destaque', {
        company_name: this.company.name
      })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    getActions (filters) {
      const actions = filters.map((_) => _.action)
      return object.reduce(actions, (_, property) => ({ [property]: false }))
    },

    clean (filters) {
      const actions = filters.map((_) => _.action)
      const params = object.reduce(this.params, (action, value) => ({
        [action]: actions.includes(action) ? false : value
      }))

      this.params = params
      this.$emit('clear-filters')
    },

    closeActions () {
      this.$emit('close-list')
    },

    saveActions () {
      this.$emit('save-highlighted', { campaigns: this.featuredCampaigns })
      this.$emit('close')
    },

    handleOrder (action) {
      const value = !this.params[action]
      if (value) this.clean(this.ordinators)
      this.params[action] = value

      const ordinators = { asc: null, desc: null }
      ordinators[action] = value ? 'name' : null
      this.$emit('update-filters', ordinators)
    },

    handleCategory (action) {
      const value = !this.params[action]
      const categories = value
        ? [...this.filters.categories, action]
        : [...this.filters.categories.filter(c => c !== action)]
      this.$emit('update-filters', { categories })
      this.params[action] = value
    },

    handleStatus (action) {
      const value = !this.params[action]
      if (value) this.clean(this.status)

      this.params[action] = value
      this.$emit('update-filters', { status: value ? action : '' })
    },

    onStart (ev) {
      this.$emit('is-dragging', true)
    },

    onEnd (ev) {
      this.$emit('is-dragging', false)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.highlight-list-wrapper {
  user-select: none;

  @include desktop {
    & > .highlight-list {
      min-height: calc(100vh - 380px);
      &.-empty { min-height: 400px; }
      & > .content { min-height: 155px; }
    }
  }

  @include mobile {
    position: absolute;
    z-index: 20;
    top: 0px;
    left: 0px;

    min-height: 100%;
    width: 100%;
    display: none;
    background-color: #F7F8FD;

    &.-is-opened { display: initial; }
    & > .highlight-list { padding-bottom: 120px; }

    & > .highlight-list > .content {
      padding: 0 10px;

      & > .item-wrapper {
        height: 160px;
        margin-bottom: 10px;

        & > .highlight-card {
          height: 100%;
        }
      }
    }

    & > .highlight-pagination {
      margin-top: -40px;
      margin-bottom: 80px;
    }
  }

  .highlight-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;

    width: 100%;

    display: flex;
    flex-direction: column;

    & > .featured-warning {
      display: flex;
      z-index: 98;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: $border;
      box-shadow: 0 -0.5px 2px 0 $shadow-color;
      height: 60px;

      & > .c-icon { fill: rgba(18, 30, 72, 0.5); margin-right: 10px; }

       & > .text {
        color: $primary-color-placeholder;
        font-weight: 100;

        & > .count { font-weight: bold; }
      }

      &.-warning {
        background-color: $failure-color;

        & > .c-icon { fill: #fff; }

        & > .text {
          color: #fff;
          & > .count { color: #fff; }
        }
      }
    }

    & > .c-button { position: static; }
  }
}

.highlight-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 30px 0 10px 0;
  padding: 0 15px;

  @include desktop {
    flex-direction: row;
    align-items: center;

    padding: 0;

    &:not(.-openedheader) { padding: 0 15px; }
    @include container;
  }

  & > .highlight-title {
    & .button-mobile-close {
      position: absolute;
      top: 20px;
      right: 15px;
      margin-left: 10px;
    }
  }

  & .header-actions {
    display: flex;
    align-items: center;
    order: 1;

    .c-button:not(:first-child) { margin-left: 10px; }
  }

  & .header-popovers > .c-popover > .content > .filters { @include popoverFilter; }

  &.-openedheader {
    padding-right: 420px;

    @media screen and (min-width: 1550px) { padding-right: 220px; }

    @media screen and (max-width: 1059px) {
      padding-right: 450px;
      flex-direction: column;
      align-items: flex-start;

      & > .header-actions {
        margin-top: 10px;
        flex-direction: column;
        width: 100%;

        & > .wrapper-search-field {
          width: 100% !important;

          & > .search-field {
            width: 100% !important;
            .input { width: 100% !important; }
          }
        }

        & > .media-inline {
          margin-top: 10px;
          display: flex;
          align-items: center;
          width: 100%;

          & > .c-button { width: 100%; }
        }
      }
    }

    @include desktop {
      & > .header-actions {
        margin-left: 10px;
        & > .media-inline { display: flex; }
      }
    }
  }

  .wrapper-search-field {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    & > .search-field > .field > .c-input > .c-icon > .st0 { opacity: 0.5; }

    .search-field > .wrapper-c-input,
    .search-field > .wrapper-c-input > .c-input-container {
      margin: 0 !important;

      & > .input { width: 230px; }
    }

    @include mobile {
      margin-top: 20px;
      margin-right: 0;

      & > .search-field { width: 100% !important; }
      & > .search-field > .wrapper-c-input { width: 100% !important; }
      & > .search-field > .wrapper-c-input .input { width: 100% !important; }
    }
  }
}

.highlight-list > .content {
  @include mobile { z-index: 50; }
  position: relative;

  & > .c-empty {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 0px;
  }

  @include desktop {
    @include list;
    margin: 0 -5px;

    & > .item-wrapper {
      flex: { basis: calc(33% - 10px); grow: 0; shrink: 0; }
      margin: 5px;
      max-width: 320px;
    }

    @media screen and (max-width: 872px) {
      &:not(.-openedList) {
        & > .item-wrapper {
          flex: { basis: calc(50% - 10px); grow: 0; shrink: 0; }
          margin: 5px;
        }
      }
    }

    @media screen and (min-width: 769px) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: stretch;
      padding: 10px 0px;
    }

    @media screen and (min-width: 892px) {
      &:not(.-openedlist) { padding: 10px 10px; }
    }

    &.-openedlist {
      padding-right: 360px;
      & > .c-empty { left: calc(50% - 200px); }

      @media screen and (max-width: 1549px) {
        & > .item-wrapper > .highlight-card {
          min-height: 155px;

          & > .content > .section-right { padding-top: 25px; }
        }
      }

      & > .item-wrapper {
        min-width: 320px;
        margin: 5px;
      }

      @media screen and (min-width:1550px) {
        padding-right: 220px;
        & > .c-empty { left: calc(50% - 100px); }
      }

      @media screen and (max-width:1059px) {
        & > .item-wrapper { flex: 100% 0 0; margin-left: 0; }
        padding-right: 450px;
        & > .c-empty { left: calc(50% - 230px); }
      }
    }
  }
}

.highlight-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 15px;

  @include desktop { @include container; }

  &.-openedlist {
    padding-right: 420px;

    @media screen and (min-width:1550px) { padding-right: 220px; }
    @media screen and (max-width:992px) { padding-right: 500px; }
  }
}

.popover-filter-group {
  @include popoverFilterGroup;
  @include mobile { min-width: auto; }
}

.popover-filter-apply.c-button.-block{ position: absolute; }

.wrapper-filter-toggle {
  margin: 10px 0;
  .c-button { width: 100%; }
}

// List Transitions
// ----------------

.highlight-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.highlight-list > .item-wrapper {
  transition: transform .2s ease-out,
              opacity .3s ease-in;
}

.highlight-list-enter,
.highlight-list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.highlight-list-leave-active {
  position: absolute;
}
</style>
