<template>
  <div class="notification-type-inputs">
    <div class="radio-group-config">
      <div class="header">
        <p class="radio-group-description">
          a notificação será atrelada a uma oferta?
        </p>
        <c-info-button
          data-cy="notification-campaign-config-info-button"
          popover-label="Caso opte por atrelar a uma oferta, ao clicar em sua notificação seu usuário será direcionado para a oferta que você configurou nesse passo."
        />
      </div>
      <radio-buttons
        data-cy="notification-campaign-config-radio"
        class="radio-buttons"
        :options="radioButtons"
        :selected-value.sync="radioInternalValue"
        :name="radioGroupName"
      />
    </div>
    <div class="offer-config-selects">
      <c-select
        data-cy="notification-partner-select"
        form-label="Parceiro"
        class="select"
        track-by="id"
        display-by="name"
        placeholder="Selecione o parceiro"
        :disabled="partnersLoading || (radioOption === 'no')"
        :items="partners || []"
        v-model="partnerInternalValue"
      />
      <c-select
        data-cy="notification-campaign-select"
        form-label="Oferta"
        class="select"
        track-by="id"
        display-by="name"
        placeholder="Selecione a oferta"
        :disabled="partnersLoading || (radioOption === 'no') || !partner"
        :items="campaigns || []"
        v-model="campaignInternalValue"
      />
    </div>
  </div>
</template>

<script>
import RadioButtons from '@/components/Dashboard/base/RadioButtons.vue'
import CInfoButton from '@/components/CComponents/CInfoButton.vue'
export default {
  components: {
    RadioButtons,
    CInfoButton
  },
  props: {
    radioOption: String,
    radioButtons: Array,
    partner: String,
    campaign: String,
    campaigns: Array,
    partners: Array,
    partnersLoading: Boolean,
    radioGroupName: String
  },
  computed: {
    radioInternalValue: {
      get () {
        return this.radioOption
      },
      set (value) {
        this.update('radio-option', value)
      }
    },
    partnerInternalValue: {
      get () {
        return this.partner
      },
      set (value) {
        this.update('partner', value)
        this.update('campaign', null)
      }
    },
    campaignInternalValue: {
      get () {
        const isCampaignPresent = this.campaigns.some(c => c.id === this.campaign);
        return isCampaignPresent ? this.campaign : null
      },
      set (value) {
        this.update('campaign', value)
      }
    }
  },
  methods: {
    update (key, value) {
      this.$emit(`update:${key}`, value)
    }
  }
}
</script>
<style lang="scss" scoped>
.radio-group-config {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  .header {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .radio-group-description {
    color: var(--cor-primary-neutral-2, #5E6684);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.5px; /* 122.727% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .radio-buttons {
    display: flex;
    gap: 24px;
  }
}
.offer-config-selects {
  display: flex;
  gap: 24px;
  .select {
    width: 100%;
    max-width: 340px;
  }
}
</style>
