<template>
  <div class=" product-card">
    <div class="dashboard-card data-card">
      <transition name="fade">
        <loading-bar v-if="loading" />
        <h4
          v-else
          class="value"
        >
          {{ cardData[0].value }}
        </h4>
      </transition>
      <span class="label">
        {{ cardData[0].label }}
        <info-button
          icon="info-information-circle"
          class="resume-info"
          :info="graphData.infos[0] || null"
        />
        <br />
      </span>
      <span>{{ cardData[0].period ? cardData[0].period : 'no período' }}</span>
    </div>
    <div class="dashboard-card data-card">
      <div>
        <transition name="fade">
          <loading-bar v-if="loading" />
          <h4
            v-else
            class="value"
          >
            {{ cardData[1].value }}
          </h4>
        </transition>
        <span class="label">
          {{ cardData[1].label }}
          <info-button
            icon="info-information-circle"
            class="resume-info"
            :info="graphData.infos[1] || null"
          />
          <br />
        </span>
        <span>{{ cardData[1].period ? cardData[1].period : 'no período' }}</span>
      </div>
      <div class="graphic-card">
        <transition name="fade">
          <loading-gauge v-if="loading" />
          <error-gauge
            data-cy="error-gauge"
            v-else-if="!loading && error"
          />
          <gauge-chart
            v-else
            data-cy="graph-chart"
            :graph-data="graphData"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'
import GaugeChart from '@/components/Dashboard/base/GaugeChart.vue'
import LoadingGauge from '@/components/Dashboard/loading/LoadingGauge.vue'
import ErrorGauge from '@/components/Dashboard/error/ErrorGauge.vue'
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'

export default {
  components: {
    LoadingBar,
    GaugeChart,
    LoadingGauge,
    ErrorGauge,
    InfoButton
  },
  props: {
    cardData: {
      type: Array,
      default: () => []
    },
    graphData: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
@import '~@/styles/dashboard';

.product-card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title {
      font-size: 28px;
      color: $base-color;
    }
  }
  .data-card:last-child {
    width: 662px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 0;
  }
  .data-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 306px;
    justify-content: center;
    padding: 0 43px;
    margin-right: 32px;
    .value {
      font-size: 32px;
      color: $base-color;
    }
    span {
      color: $primary-neutral-2;
      &.label {
        font-size: 18px;
        font-weight: 700;
        color: $base-color;
        display: flex;
        .resume-info {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
