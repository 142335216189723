<template>
  <div
    data-cy="vidalink-card"
    class="vidalink-card"
  >
    <div class="title-container">
      <h3 class="title">
        <k-icon
          icon="kaledo-vida"
          :is-colorful="true"
          :number-of-colors="6"
          class="icon-title"
        />
        Ativações do cartão
      </h3>
      <info-button
        icon="info-information-circle"
        :info="info || null"
      />
    </div>
    <div class="content">
      <loading-card v-if="isVidalinkDataLoading" />
      <not-available
        v-else-if="hasVidalinkDataError || isDataEmpty"
        :type="hasVidalinkDataError ? 'error' : 'empty'"
      />
      <vertical-bar
        v-else
        class="partners-chart-card"
        :title="chartTitle"
        :categories="categories"
        :columns="vidalinkColumns"
        :is-money="false"
        :names="vidalinkNames"
        :very-high-numbers="false"
        label="Ativações"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { PRODUCTS_DASH_VIDALINK_BAR_DATA } from '@/store/types'
import { KIcon } from 'kaledo-components'
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'
import VerticalBar from '@/components/Dashboard/base/VerticalBar.vue'
import notAvailable from '@/components/Dashboard/base/notAvailable.vue'

import LoadingCard from '@/components/Dashboard/loading/LoadingCard.vue'

export default {
  name: 'VidalinkCard',
  components: {
    KIcon,
    InfoButton,
    notAvailable,
    VerticalBar,
    LoadingCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      isVidalinkDataLoading: false,
      hasVidalinkDataError: false,
      vidalinkNames: {
        data1: 'Ativações'
      },
      vidalinkColumns: [
        [
          ['x', 'jan', 'fev', 'mar', 'abril', 'mai', 'jun'],
          ['Ativações', 0, 0, 0, 0, 0, 0]
        ]
      ]
    }
  },
  computed: {
    chartTitle () {
      return 'Vidalink'
    },
    categories () {
      return ['x', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun']
    },
    isDataEmpty () {
      const columns = this.vidalinkColumns
      if (typeof columns === 'undefined') {
        return true
      } else if (Array.isArray(columns)) {
        if (Array.isArray(columns[0])) {
          let isArrayEmpty = true
          columns.forEach(column => {
            if (column[0] !== 'x') {
              const colum = column.slice(1)
              if (Math.max(...colum) !== 0) {
                isArrayEmpty = false
              }
            }
          })
          return isArrayEmpty
        } else {
          const isEmpty = columns.findIndex(value => parseInt(value) !== 0)
          return isEmpty === -1
        }
      } else {
        const values = Object.values(columns)
        const isEmpty = values.findIndex(value => parseInt(value) !== 0)
        return isEmpty === -1
      }
    },
    info () {
      return {
        title: 'Ativações do cartão',
        text: 'Quantidade de cartões que foram ativados no período selecionado.'
      }
    }
  },
  watch: {
    reloadDataQuery (newval) {
      if (newval) {
        this.loadGraphs()
      }
    }
  },
  mounted () {
    this.loadGraphs()
  },
  methods: {
    ...mapActions({
      getVidalinkData: PRODUCTS_DASH_VIDALINK_BAR_DATA
    }),
    async loadGraphs () {
      this.isVidalinkDataLoading = true
      const response = await this.getVidalinkData()
      if (response && response.success) {
        this.loadData(response.data)
      } else {
        this.hasVidalinkDataError = true
      }
      this.isVidalinkDataLoading = false
    },
    loadData (data) {
      this.vidalinkColumns = [...data.columns]
      this.vidalinkNames = { ...data.names }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.vidalink-card {
  background: #FDFDFD;
  width: 100%;
  background: white;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  .title-container {
    display: flex;
    align-items: center;
    .title {
      font-size: 18px;
      color: $base-color;
      display: flex;
      align-items: center;
      margin-right: 8px;
      .icon-title {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
  .content {
    width: 100%;
    margin-top: 48px;
  }
}
</style>
