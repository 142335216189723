<template>
  <div class="step-two">
    <div class="step-form">
      <h3 class="section-title">
        Personalização
      </h3>
      <instruction
        class="instruction"
        text="Insira uma imagem e escreva o conteúdo da sua notificação"
      />
      <div class="form-inputs">
        <div class="input">
          <c-input
            data-cy="notification-title-input"
            class="input"
            name="title"
            label="Título da notificação"
            required
            maxlength="38"
            v-validate="'required'"
            placeholder="Insira um título para sua notificação"
            :value="stepValue.title"
            :feedback-show="errors.has('title') || !titleValidation"
            :feedback-message="titleValidationMessage"
            @input="update('title', $event)"
            @click.native="hasTitleTouched = true"
          />
          <c-length-input-text
            :show="!errors.has('title') && titleValidation"
            length="38"
          />
        </div>
        <div class="input">
          <c-input
            data-cy="notification-message-input"
            class="input message"
            name="message"
            label="Mensagem da notificação"
            required
            textarea
            rows="5"
            maxlength="118"
            v-validate="'required'"
            placeholder="Insira a mensagem da sua notificação"
            :value="stepValue.message"
            :feedback-show="errors.has('message') || !messageValidation"
            :feedback-message="messageValidationMessage"
            @input="update('message', $event)"
            @click.native="hasMessageTouched = true"
          />
          <c-length-input-text
            :show="!errors.has('message') && messageValidation"
            length="134"
          />
        </div>
        <div class="image-input">
          <label class="label">
            Imagem da notificação
          </label>
          <upload-image
            data-cy="notification-image-input"
            class="upload-image-component"
            :required-width="64"
            :required-height="64"
            :is-edit="false"
            @change="handleImage"
          />
          <span class="sub">
            Formato: <b>png</b><br />
            Tamanho: <b>64x64px</b>
          </span>
        </div>
      </div>
      <div class="preview-area">
        <h3 class="section-title">
          Pré-visualização
        </h3>
        <order-communication-preview-card
          data-cy="communication-preview-card"
          :title="stepValue.title"
          :message="stepValue.message"
          :image="imageSrc"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CLengthInputText from '@/components/CComponents/CLengthInputText'
import UploadImage from '@/components/General/UploadImage.vue'
import Instruction from '@/components/General/Instruction'
import OrderCommunicationPreviewCard from '@/components/General/OrderCommunicationPreviewCard.vue'
import { minLengthValidation, maxLengthValidation } from '@/modules/validate/validators.js'
import { minLengthValidationMessage, maxLengthValidationMessage } from '@/modules/validate/helpers.js'

export default {
  components: {
    CLengthInputText,
    UploadImage,
    Instruction,
    OrderCommunicationPreviewCard
  },
  props: {
    stepValue: Object,
    previewData: Object
  },
  data () {
    return {
      hasTitleTouched: false,
      hasMessageTouched: false,
      campaign: {},
      image: ''
    }
  },
  computed: {
    titleValidation () {
      if (!this.stepValue.title) return false
      return (maxLengthValidation(this.stepValue.title, 38) && minLengthValidation(this.stepValue.title, 5)) || !this.hasTitleTouched
    },
    titleValidationMessage () {
      if (!this.stepValue.title) return 'Campo obrigatório'
      if (!maxLengthValidation(this.stepValue.title, 38)) return maxLengthValidationMessage('título', 38)
      else if (!minLengthValidation(this.stepValue.title, 5)) return minLengthValidationMessage('título', 5)
      return 'Campo obrigatório'
    },
    messageValidation () {
      if (!this.stepValue.message) return false
      return (maxLengthValidation(this.stepValue.message, 134) && minLengthValidation(this.stepValue.message, 5)) || !this.hasMessageTouched
    },
    messageValidationMessage () {
      if (!this.stepValue.message) return 'Campo obrigatório'
      if (!maxLengthValidation(this.stepValue.message, 134)) return maxLengthValidationMessage('mensagem', 134)
      else if (!minLengthValidation(this.stepValue.message, 5)) return minLengthValidationMessage('mensagem', 5)
      return 'Campo obrigatório'
    },
    imageSrc () {
      if (!this.image || this.image.length === 0) return ''
      if (typeof this.image === 'string') return this.image
      return URL.createObjectURL(this.image)
    }
  },
  created () {
    Object.keys(this.stepValue).forEach(key => {
      this[key] = this.stepValue[key]
    })
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    },
    handleImage (files) {
      this.image = files
      this.update('image', this.image)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.step-two {
  max-width: 1020px;
  padding: 0;

  > .step-two-title {
    display: flex;
    height: 35px;
    margin-bottom: 20px;

    > .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  .step-form {
    width: 100%;

    .section-title {
      margin-top: 48px;
      margin-bottom: 16px;
      color: #565F7D;
    }
    .instruction {
      margin-bottom: 16px;
    }

    & > .title {
      padding-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #202950;
    }

    .subtext {
      color: #5E6684;
      font-size: 14px;
    }
    .form-inputs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 32px;

      .input {
        width: 100%;
        max-width: 340px;

        &.message {
          :deep textarea {
            resize: none;
          }
        }
        :deep .label {
          .required {
            display: none;
          }
        }
      }
      .image-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 8px;

        .label {
          color: rgba(18, 30, 72, 0.8);
          text-transform: uppercase;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 9px;
        }
        .upload-image-component {
          margin: 0;
        }
      }
    }
    .preview-area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

  }
}
</style>
