<template>
  <under-construction-card
    v-if="underConstruction"
    :tab-name="tabName"
  />
  <div
    class="dashboard-products-tab"
    v-else
  >
    <product-resume-card
      title="Prêmios"
      icon="kaledo-premios-trofeu"
      data-cy="awards-product-resume"
      :reload-data-query="loading"
      :loading="isAwardsResumeLoading"
      :error="hasAwardsResumeError"
      :card-data="awardsData"
      :graph-data="awardsChartData"
    />
    <awards-data
      :reload-data-query="loading"
    />
    <product-resume-card
      title="Pontos"
      icon="kaledo-premios"
      data-cy="engagement-product-resume"
      :loading="isEngagementResumeLoading"
      :error="hasEngagementResumeError"
      :card-data="engagementData"
      :graph-data="engagementChartData"
    />
    <engagement-data
      :reload-data-query="loading"
    />
    <product-resume-card
      title="Vidalink"
      icon="kaledo-vida"
      data-cy="vidalink-product-resume"
      :loading="isVidalinkResumeLoading"
      :error="hasVidalinkResumeError"
      :card-data="vidalinkData"
      :graph-data="vidalinkChartData"
    />
    <vidalink-card
      :reload-data-query="loading"
    />
  </div>
</template>

<script>
import ProductResumeCard from '@/components/Dashboard/cards/ProductsTab/ProductResumeCard.vue'
import UnderConstructionCard from '@/components/Dashboard/cards/UnderConstructionCard.vue'
import { PRODUCTS_DASH_AWARDS_RESUME, PRODUCTS_DASH_ENGAGEMENT_RESUME, PRODUCTS_DASH_VIDALINK_RESUME } from '@/store/types'
import { mapActions } from 'vuex'
import AwardsData from '@/components/Dashboard/graphics/AwardsData.vue'
import EngagementData from '@/components/Dashboard/graphics/EngagementData.vue'
// import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import VidalinkCard from '@/components/Dashboard/cards/VidalinkCard.vue'
// import AwardsCard from '@/components/Dashboard/cards/AwardsCard.vue'

export default {
  name: 'DashboardProductsTab',
  components: {
    ProductResumeCard,
    UnderConstructionCard,
    AwardsData,
    EngagementData,
    VidalinkCard
    // CampaignsBarCard,
    // AwardsCard,
  },
  props: {
    underConstruction: Boolean,
    tabName: String,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isAwardsResumeLoading: false,
      hasAwardsResumeError: false,
      isEngagementResumeLoading: false,
      hasEngagementResumeError: false,
      isVidalinkResumeLoading: false,
      hasVidalinkResumeError: false,
      awardsChartData: {
        title: 'Resgates por usuários premiados',
        infos: [
          {
            title: 'Pedidos realizados',
            text: 'Número de pedidos de distribuição de prêmios realizados no período'
          },
          {
            title: 'Usuários premiados',
            text: 'Quantidade de usuários que receberam um valor em prêmio.'
          }
        ],
        gauge: {
          title: 'Resgates por usuários premiados',
          description: 'Porcentagem de usuários premiados que resgataram algum prêmio.'
        },
        columns: [80]
      },
      awardsData: {
        data: [
          {
            value: 25,
            label: 'Pedidos realizados'
          },
          {
            value: 500,
            label: 'Usuários premiados'
          }
        ]
      },
      engagementChartData: {
        title: 'Resgates por usuários recompensados',
        infos: [
          {
            title: 'Pedidos realizados',
            text: 'Número de pedidos de distribuição de pontos realizados no período.'
          },
          {
            title: 'Usuários recompensados',
            text: 'Quantidade de usuários que receberam uma quantia de pontos.'
          }
        ],
        gauge: {
          title: 'Resgates por usuários recompensados',
          description: 'Porcentagem de usuários que receberam pontos como recompensa e os utilizaram para resgatar alguma oferta'
        },
        columns: [0]
      },
      engagementData: {
        data: [
          {
            value: 0,
            label: 'Pedidos realizados'
          },
          {
            value: 0,
            label: 'Usuários recompensados'
          }
        ]
      },
      vidalinkChartData: {
        title: 'Usuários que ativaram o vidalink',
        infos: [
          {
            title: 'Cartões ativos',
            text: 'Quantidade total de cartões Vidalink que estão ativos.'
          },
          {
            title: 'Cartões ativados',
            text: 'Quantidade de cartões Vidalink que foram ativados no período selecionado.'
          }
        ],
        gauge: {
          title: 'Porcentagem de ativação',
          description: 'É a relação entre o número de usuários do seu clube e a quantidade total de cartões Vidalink ativos.'
        },
        columns: [0]
      },
      vidalinkData: {
        data: [
          {
            value: 0,
            label: 'Cartões ativos',
            period: 'atualmente'
          },
          {
            value: 0,
            label: 'Cartões ativados'
          }
        ]
      }
    }
  },
  watch: {
    loading (newval) {
      if (newval) {
        this.loadGraphs()
      }
    }
  },
  mounted () {
    this.loadGraphs()
  },
  methods: {
    ...mapActions({
      getAwardsResume: PRODUCTS_DASH_AWARDS_RESUME,
      getEngagementResume: PRODUCTS_DASH_ENGAGEMENT_RESUME,
      getVidalinkResume: PRODUCTS_DASH_VIDALINK_RESUME
    }),
    loadGraphs () {
      this.initAwardsResume()
      this.initEngagementResume()
      this.initVidalinkResume()
    },
    async initAwardsResume () {
      this.isAwardsResumeLoading = true
      const response = await this.getAwardsResume()
      if (response && response.success) {
        this.hasAwardsResumeError = false
        this.changeAwardsData(response.data)
      } else {
        this.hasAwardsResumeError = true
      }
      this.isAwardsResumeLoading = false
    },
    changeAwardsData (data) {
      this.awardsChartData.columns = [data.redemption_rate]
      this.awardsData.data[0].value = data.total_orders
      this.awardsData.data[1].value = data.awarded_users
    },
    async initEngagementResume () {
      this.isEngagementResumeLoading = true
      const response = await this.getEngagementResume()
      if (response && response.success) {
        this.hasEngagementResumeError = false
        this.changeEngagementData(response.data)
      } else {
        this.hasEngagementResumeError = true
      }
      this.isEngagementResumeLoading = false
    },
    changeEngagementData (data) {
      this.engagementChartData.columns = [data.redemption_rate]
      this.engagementData.data[0].value = data.total_orders
      this.engagementData.data[1].value = data.awarded_users
    },
    async initVidalinkResume () {
      this.isVidalinkResumeLoading = true
      const response = await this.getVidalinkResume()
      if (response && response.success) {
        this.hasVidalinkResumeError = false
        this.changeVidalinkData(response.data)
      } else {
        this.hasVidalinkResumeError = true
      }
      this.isVidalinkResumeLoading = false
    },
    changeVidalinkData (data) {
      this.vidalinkChartData.columns = [data.activation_rate]
      this.vidalinkData.data[0].value = data.total_active_cards
      this.vidalinkData.data[1].value = data.total_active_cards_period
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-products-tab {
  display: flex;
  flex-direction: column;
  gap: 32px;

 .card {
    position: relative;
    background: white;
    z-index: 1;
    padding: 16px;
    width: 326px;
    height: 200px;
    border-radius: 6px 6px 0px 0px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
  }

  .awards-card {
    grid-column: 1 / -1;
  }
}
</style>
