<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="onboarding-modal"
    :class="openedTopic !== null ? '-opened' : ''"
    name="onboarding-modal"
  >
    <div class="modal-header">
      <h2
        data-cy="onboarding-title"
        class="title"
      >
        {{ title }}
      </h2>
    </div>
    <div
      class="modal-content"
      ref="onboarding-modal-content"
    >
      <div class="modal-subtitle">
        <span
          data-cy="onboarding-subtitle"
          class="subtitle"
        >
          Clique nos tópicos abaixo para saber mais
        </span>
      </div>
      <div
        class="topic"
        v-for="(topic, i) in topics"
        :key="topic.title"
        data-cy="topic"
      >
        <div
          class="topic-header"
          @click="handleOpenTopic(i)"
        >
          <k-icon
            v-if="topic.icon"
            class="icon title-icon"
            :icon="topic.icon"
          />
          <h3 class="title">
            {{ topic.title }}
          </h3>
          <k-icon
            class="icon"
            data-cy="topic-open-icon"
            :icon="openedTopic === i ? 'arrow-up' : 'arrow-down1'"
          />
        </div>
        <transition
          name="slide-fade"
        >
          <div
            data-cy="topic-content"
            class="topic-content"
            v-show="openedTopic === i"
          >
            <p
              v-for="(content, index) in topic.contents"
              :key="`topic-content-${index}`"
              class="topic-text"
              :id="`topic-content-${index}`"
            >
              {{ content }}
            </p>
          </div>
        </transition>
      </div>
    </div>
  </c-modal>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CModal from '@/components/CComponents/CModal'

export default {
  components: {
    KIcon,
    CModal
  },
  props: {
    showModal: Boolean,
    title: String,
    topics: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      openedTopic: null
    }
  },
  watch: {
    showModal () {
      this.openedTopic = null
    }
  },
  methods: {
    handleOpenTopic (topic) {
      if (topic === this.openedTopic) {
        this.openedTopic = null
      } else {
        this.openedTopic = topic
        if (topic > 3) {
          this.$nextTick(() => {
            this.$refs['onboarding-modal-content'].scrollIntoView({
              behavior: 'smooth',
              block: 'end'
            })
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-active {
  transition: opacity 0.7s ease;
}

.onboarding-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  :deep .c-modal-mask {
    display: flex;
    justify-content: center;
  }

  :deep .c-modal {
    width: 850px !important;
    max-width: 100% !important;
    padding: 0;
    transition: all 0.5s;
    overflow-y: scroll;

    @media screen and (min-width: 800px),
          screen and (max-width: 1050px) {
      left: 10%;
    }
  }

  &.-opened {
    :deep .c-modal {
      top: 50px;
    }
  }

  > .close {
    top: 10px;
    right: 10px;
    background: none;

    svg {
      fill: #5E6684;
    }
  }

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;

    @include mobile { justify-content: center; }

    .modal-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #FDFDFD;
      padding: 24px;
      height: 80px;
      width: 100%;

      .title {
        text-align: start;
        font-size: 18px;
        color: #202950;
      }
    }

    .modal-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;

      .modal-subtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        background: #F8F9FD;
        padding: 10px;

        > .subtitle {
          font-weight: 400;
          font-size: 16px;
          color: #202950;
        }
      }

      .topic {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(216, 216, 216, 1);

        .topic-header {
          position: relative;
          z-index: 5;
          width: 100%;
          height: 68px;
          display: flex;
          align-items: center;
          background: white;
          cursor: pointer;

          .title-icon {
            margin-right: 8px;
          }

          .title {
            color: #202950;
            width: 100%;
          }
          .icon {
            color: #202950;
            font-size: 20px;
            transition: all 0.5s;
            cursor: pointer;
          }
        }

        .topic-content {
          position: relative;
          margin-bottom: 24px;
          z-index: 3;

          .topic-text {
            color: #202950;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #202950;

        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
