<template>
  <div class="vertical-bar">
    <div class="legends">
      <div
        v-for="(label, index) in labels"
        :key="`${label}-${index}`"
        class="graphic-legend"
      >
        <div :class="`color -color-${index}`" />
        <span class="text">{{ label }}</span>
      </div>
    </div>
    <vue-c3
      :class="veryHighNumbers ? 'very-high-numbers' : ''"
      :handler="handler"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default {
  name: 'VerticalBar',
  components: {
    VueC3
  },
  props: {
    graphData: Object,
    categories: Array,
    names: {
      type: Object,
      default: () => {}
    },
    columns: Array,
    title: String,
    isMoney: {
      type: Boolean,
      default: true
    },
    label: String,
    veryHighNumbers: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      handler: new Vue()
    }
  },
  computed: {
    labels () {
      const label = this.formatedGroupsColumns.filter(column => column[0] !== 'x').map(column => {
        if (this.names && this.names[column[0]]) {
          return this.names[column[0]]
        }
        return column[0]
      })
      return label
    },
    groups () {
      const label = this.formatedGroupsColumns.map(column => column[0])
      return label
    },
    formatedGroupsColumns () {
      if (this.columns.length === 1) {
        return [this.categories, this.columns[0]]
      }
      let formatedColumns = []
      if (this.columns[0][0] === 'x') {
        formatedColumns = this.columns.map((column, index) => {
          if (index > 0) {
            return column.map((element, index) => {
              return (typeof element !== 'string' && this.columns.length >= 3) ? this.columns[1][index] - this.columns[0][index] : element
            })
          }
          return column
        })
      } else {
        formatedColumns = [this.categories, this.columns[0], this.columns[1].map((element, index) => {
          return typeof element !== 'string' ? this.columns[1][index] - this.columns[0][index] : element
        })]
      }
      return formatedColumns
    },
    maxArrayValue () {
      let splittedBiggestArray = this.columns[0].slice(1)
      if (this.columns.length > 1) {
        let columns = [...this.columns]
        if (this.columns[0][0] === 'x') {
          columns = [...this.columns.slice(1)]
        }
        const newArray = []
        columns.map(column => {
          const newColumn = column.slice(1).map(col => +col)
          newArray.push(...newColumn)
          return newColumn
        })
        splittedBiggestArray = newArray
      }
      const highestArrayValue = Math.max(...splittedBiggestArray)
      return highestArrayValue
    },
    axisTick () {
      const highestArrayValue = this.maxArrayValue
      let maxValue = 0
      let dividedBy = 10
      if (highestArrayValue && highestArrayValue > 0 && highestArrayValue < 50) {
        maxValue = Math.ceil(highestArrayValue / 2) * 2
        dividedBy = 2
      } else if (highestArrayValue && highestArrayValue > 50) {
        maxValue = Math.ceil(highestArrayValue / 50) * 50
      }
      const ticks = []
      let i = 0
      if (maxValue !== 0) {
        if (highestArrayValue < 50 && highestArrayValue % 2 !== 0) {
          ticks.push(highestArrayValue)
          maxValue = maxValue - 1
        }
        while (i <= maxValue) {
          ticks.push(i)
          if (highestArrayValue < 10) {
            i += 1
          } else {
            const result = Math.ceil(maxValue / dividedBy)
            i += result
          }
        }
      } else {
        ticks.push(0)
      }
      return ticks
    },
    options () {
      return {
        padding: {
          left: this.veryHighNumbers ? 85 : 65,
          top: this.label ? 20 : 0
        },
        data: {
          x: 'x',
          columns: this.formatedGroupsColumns,
          names: this.names,
          type: 'bar',
          order: null,
          classes: {
            Ativas: 'active-bars',
            Disponíveis: 'available-bars',
            title: this.title
          }
        },
        legend: {
          hide: true
        },
        bar: {
          zerobased: true,
          width: 8
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'category'
          },
          y: {
            label: this.label ? this.label : '',
            position: 'outer-center',
            padding: {
              top: 30,
              bottom: 30
            },
            tick: {
              fit: true,
              count: 5,
              values: this.axisTick,
              max: this.maxArrayValue,
              format: (this.isMoney
                ? function (x) {
                  return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(x / 100)
                }
                : function (x) {
                  return `${x}`
                })

            }
          }
        },
        tooltip: {
          contents: (this.isMoney
            ? function (d) {
              const meta = this.config.data_classes
              const title = meta.title ? `<h5 class="tooltip-title">${meta.title}</>` : ''
              const formatValue = (value) => {
                return new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(value / 100)
              }
              if (d.length === 1) {
                return `<div class="custom-tooltip">
                ${title}
                <h6 class="tooltip-label"> ${d[0].name}</h6>
                <p class="tooltip-description">${formatValue(d[0].value)}</p>
              </div>`
              }
              return `<div class="custom-tooltip">
              ${title}
              <h6 class="tooltip-label">${d[0].name}</h6>
              <p class="tooltip-description">${formatValue(d[0].value)}</p>
              <h6 class="tooltip-label">${d[1].name}</h6>
              <p class="tooltip-description">${formatValue(d[1].value)}</p>
            </div>`
            }
            : function (d) {
              const meta = this.config.data_classes
              const title = meta.title ? `<h5 class="tooltip-title">${meta.title}</>` : ''
              if (d.length === 1) {
                return `<div class="custom-tooltip">
                ${title}
                <h6 class="tooltip-label">${d[0].name}</h6>
                <p class="tooltip-description">${d[0].value}</p>
              </div>`
              }
              return `<div class="custom-tooltip">
              ${title}
              <h6 class="tooltip-label">${d[0].name}</h6>
              <p class="tooltip-description">${d[0].value}</p>
              <h6 class="tooltip-label">${d[1].name}</h6>
              <p class="tooltip-description">${d[1].value}</p>
            </div>`
            })
        },
        color: {
          pattern: ['#202950', '#00B2E3', '#202950', '#ff7f0e', '#ffbb78']
        }
      }
    }
  },
  watch: {
    columns () {
      this.handler.$emit('init', this.options)
      this.addEvent()
    }
  },
  mounted () {
    this.handler.$emit('init', this.options)
    this.addEvent()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    formatToCurrency (value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)
    },
    addEvent () {
      const activeBars = document.querySelectorAll('.c3-bars-Ativas > .c3-shape')
      const availableBars = document.querySelectorAll('.c3-bars-Disponíveis > .c3-shape')
      const tooltip = document.querySelectorAll('.horizontal-bar > .vuec3-chart > .c3-tooltip-container')[0]
      const bars = [activeBars, availableBars]

      function setBarStyle (index, activeColor, availableColor) {
        activeBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = targetElement.style.stroke = activeColor
          }
        })
        availableBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = availableColor
          }
        })

        return true
      }

      bars.forEach(barsGroup => {
        barsGroup.forEach((element, index) => {
          element.addEventListener('mouseover', (event) => {
            tooltip.style.display = 'block'
            setBarStyle(index, '#D8E0F6', '#D8D8D8')
          })
          element.addEventListener('mouseout', (event) => {
            tooltip.style.display = 'none'
            setBarStyle(index, '#202950', '#D8E0F6')
          })
        })
      })
    },
    removeEvent () {
      const activeBars = document.querySelectorAll('.c3-bars-Ativas > .c3-shape')
      const availableBars = document.querySelectorAll('.c3-bars-Disponíveis > .c3-shape')
      const tooltip = document.querySelectorAll('.horizontal-bar > .vuec3-chart > .c3-tooltip-container')[0]
      const bars = [activeBars, availableBars]

      function setBarStyle (index, activeColor, availableColor) {
        activeBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = targetElement.style.stroke = activeColor
          }
        })
        availableBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = availableColor
          }
        })

        return true
      }

      bars.forEach(barsGroup => {
        barsGroup.forEach((element, index) => {
          element.removeEventListener('mouseover', (event) => {
            tooltip.style.display = 'block'
            setBarStyle(index, '#D8E0F6', '#D8D8D8')
          })
          element.removeEventListener('mouseout', (event) => {
            tooltip.style.display = 'none'
            setBarStyle(index, '#202950', '#D8E0F6')
          })
        })
      })
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference.scss';
$active-color: #202950;
$available-color: #00B2E3;
$available-hover-color: #D8D8D8;

.vertical-bar {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  // .c3 svg {
  //   width: 100%;
  //   transform: translate(-10px,0);
  // }
  .c3-ygrids {
    line:not([stroke-width="10"]) {
      stroke: rgba(190, 192, 211, 1) !important;
      stroke-dasharray: 0;
    }
  }
  .c3 path.domain {
    display: none !important;
  }
  .c3-axis-y text,  .c3-axis-x text{
    transform: translate(-50px,0);
    fill: rgba(94, 102, 132, 1);
    font-size: 14px;
    font-family: $base-font-family;
    text-anchor: start !important;
  }
  .c3-axis-y text {
    transform: translate(-35px,0);
  }
  .c3-axis-y text.c3-axis-y-label {
    transform: translate(-55px, -25px)
  }
  .very-high-numbers .c3-axis-y text {
    transform: translate(-75px,0);
  }
  .c3-axis-x .tick line, .c3-axis-x .tick text {
    transform: translate(-10px,0);
    word-spacing: 1px;
  }
  .c3 line {
    stroke: none;
  }
  // .c3-target {
  //   pointer-events: auto !important;
  // }
  // .c3-shape {
  //   transition: 0.3s;
  // }

  .legends {
    width: 100%;
    padding: 10px 50px;
    display: flex;
    justify-content: flex-end;
    .graphic-legend {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      .text {
        font-size: 12px;
        color: $text-color;
      }
      .color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #D8E0F6;
        margin-right: 5px;
        &.-color-0 {
          background: #202950;
        }
        &.-color-1 {
          background: #00B2E3;
        }
      }
    }
    .graphic-legend {
      margin-left: 50px;
    }
  }

  .c3-tooltip-container {
    transition: 0.3s;
    background: white;
    box-shadow: rgba(0,0,0,0.1);
    border-radius: 3px;
  }
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 170px;
    min-height: 110px;
    font-size: 11px;
    padding: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    .tooltip-title {
      color: #202950;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .tooltip-label {
      color: $text-color;
      margin-bottom: 2px;
      font-size: 11px;
      font-weight: 400;
    }
    .tooltip-description {
      color: $title-color;
      font-size: 13px;
      font-weight: 600;
    }
    .tooltip-description:nth-of-type(1) {
      margin-bottom: 5px;
    }
    .tooltip-comparison {
      &.-error {
        color: $like-color;
      }
      &.-success {
        color: $success-color;
      }
    }
  }
}

</style>
