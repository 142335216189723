<template>
  <div class="mini-card">
    <div class="header">
      <transition
        name="fade"
        mode="in-out"
      >
        <loading-bar v-if="loading" />
        <h3
          class="subtitle"
          v-else-if="subTitle"
        >
          {{ totalAdjusted }} {{ subTitle }}
        </h3>
        <h3
          class="title"
          v-else
        >
          {{ totalAdjusted }}
        </h3>
      </transition>
      <info-button
        v-if="!noIcon"
        :icon="iconAdjusted"
        :modal="showModal"
        :info="info"
      />
    </div>

    <h4
      class="label"
    >
      <template v-if="wordBreak">
        {{ labelPart1 }}
        <br />
        {{ labelPart2 }}
      </template>
      <template v-else>
        {{ label }}
      </template>
    </h4>
    <slot />
  </div>
</template>

<script>
import LoadingBar from '@/components/Dashboard/loading/LoadingBar.vue'
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'

export default {
  name: 'MiniCard',
  components: {
    LoadingBar,
    InfoButton
  },
  props: {
    total: [String, Number],
    subTitle: String,
    label: String,
    icon: String,
    loading: Boolean,
    noIcon: {
      type: Boolean,
      default: false
    },
    wordBreak: {
      type: Boolean,
      default: true
    },
    isTimeValue: {
      type: Boolean,
      default: false
    },
    formatLabel: Boolean,
    info: Object,
    showZero: Boolean
  },
  computed: {
    labelPart1 () {
      const label = this.label.split(' ')
      label.pop()
      label.pop()
      const finalLabel = label.join(' ')
      if (finalLabel.length > 0) return finalLabel
      return ''
    },
    labelPart2 () {
      const label = this.label.split(' ')
      if (label.length > 2) {
        return ` ${label[label.length - 2]} ${label[label.length - 1]}`
      }
      return this.label
    },
    totalAdjusted () {
      if (!this.total || (this.total && parseFloat(this.total) === 0)) {
        return this.showZero ? 0 : ' - '
      }
      if (this.isTimeValue) {
        return this.secondsToMinutes(this.total)
      }
      return parseFloat(this.total).toLocaleString()
    },
    iconAdjusted () {
      if (!this.icon) return 'info-information-circle'
      return this.icon
    },
    showModal () {
      if (this.iconAdjusted === 'info-information-circle') return true
      return false
    },
    formatedLabel () {
      return this.label.split('no período')[0]
    }
  },
  methods: {
    secondsToMinutes (seconds) {
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      let extraSeconds = Math.floor(seconds % 60)
      hours = hours !== 0 ? `${hours}h ` : ''
      minutes = minutes !== 0 ? `${minutes % 60}min ` : ''
      extraSeconds = extraSeconds !== 0 ? `${extraSeconds}s` : ''

      return `${hours}${minutes}${extraSeconds}`
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.mini-card {
  max-width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: $base-color;
      font-family: $base-font-family;
      font-weight: 700;
      font-size: 18px;
    }
    .subtitle {
      color: $base-color;
      font-family: $base-font-family;
      font-weight: 700;
      font-size: 15px;
    }
  }
  .label {
    font-family: $base-font-family;
    line-height: 20px;
    margin-top: 4px;
    color: #5E6684;
    font-weight: 400;
    font-size: 16px;
    min-height: 40px;
    max-width: 180px;
  }
}
</style>
