<template>
  <div class="partners-card">
    <div class="partners-tabs">
      <mini-card
        v-for="(card, index) in tabs"
        :key="`mini-card-${index}`"
        class="card"
        :class="selectedTab === index ? 'selected-tab' : ''"
        :total="card.total"
        :label="card.label"
        :icon="card.icon"
        :info="card.info"
        :loading="loading"
        @click.native="changeTab(index)"
      />
    </div>
    <loading-card
      v-if="loading"
      :height="390"
    />
    <horizontal-bar
      v-else
      class="partners-chart-card"
      :title="selectedTitle"
      :categories="categories"
      :columns="selectedColumns"
    />
  </div>
</template>

<script>
import LoadingCard from '@/components/Dashboard/loading/LoadingCard.vue'
import HorizontalBar from '@/components/Dashboard/base/HorizontalBar.vue'
import MiniCard from '@/components/Dashboard/cards/MiniCard.vue'

export default {
  name: 'PartnerCard',
  components: {
    LoadingCard,
    HorizontalBar,
    MiniCard
  },
  props: {
    loading: Boolean
  },
  data () {
    return {
      tabs: [
        {
          total: 500,
          label: 'Parcerias Exclusivas ativas no momento',
          icon: 'info-information-circle',
          chart_title: 'Exclusivas',
          info: {
            title: 'Parcerias Exclusivas ativas no momento',
            text: 'Parcerias Exclusivas ativas no momento'
          }
        },

        {
          total: 800,
          label: 'Parcerias Gerais ativas no momento',
          icon: 'info-information-circle',
          chart_title: 'Gerais',
          info: {
            title: 'Parcerias Gerais ativas no momento',
            text: 'Parcerias Gerais ativas no momento'
          }
        }
      ],
      selectedTab: 1,
      categories: ['x', 'Produtos e serviços', 'Lazer', 'Bem-estar e saúde', 'Alimentos e bebidas', 'Educação', 'Mercado'],
      columns: [
        [
          ['Ativas', 160, 125, 125, 125, 90, 60],
          ['Disponíveis', 240, 260, 300, 300, 140, 260]
        ],
        [
          ['Ativas', 260, 155, 150, 125, 150, 120],
          ['Disponíveis', 340, 280, 250, 200, 240, 260]
        ]
      ]
    }
  },
  computed: {
    selectedColumns () {
      return this.columns[this.selectedTab]
    },
    selectedTitle () {
      return this.tabs[this.selectedTab].chart_title
    }
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.partners-card {
  margin-top: 20px;
  max-width: 650px;

  > .partners-tabs {
    display: flex;

    > .card {
      cursor: pointer;
      background: #F8F9FD;
      width: 260px;
      height: 130px;
      padding: 16px;
      box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
      border-radius: 6px 6px 0 0;
      transition: all ease 0.3s;

      & :deep h4 {
        max-width: 240px;
      }

      &:not(.selected-tab) {
        &:hover {
          opacity: 0.5;
        }
      }
    }

     > .card:last-child {
      margin-left: 10px;
    }

    > .selected-tab {
      background: #FDFDFD;
    }
  }

  > .loading-card {
    width: 650px;
  }

  > .partners-chart-card, > .loading-card {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    padding: 16px;
    max-width: 700px;
    height: 390px;
    background: white;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(18,30,72,0.05);
    border-radius: 0 6px 6px 6px;
  }
}
</style>
